<template>
  <div class="flex flex-wrap">
    <div class="w-full flex flex-col gap-4">

          <TableApproveReject
              :columnsList="columns"
              :tableData="dataValues"
              :onClickedRows="onClickRows"
              :paginationConfig="paginationConfig"
              :filterTable="filterTable"
              :sortBy="sort_by"
              :sortChanged="
                (v) => {
                  sort_by = v;
                  fetchApproveRejectList();
                }
              "
            />
      </div>
    </div>
  </template>
  <script>
  import TableApproveReject from "../../components/TableApproveReject.vue";

  
  import { HTTP } from "../../http-common";
  import DateFunction from "../../services/DateFunction";
  
  export default {
    name: "ApproveReject",
    components: {
      TableApproveReject,


    },
    mounted() {
      this.fetchApproveRejectList();
      this.$store.commit("setBread", this.$router.currentRoute._value);
    },
    methods: {
      async fetchApproveRejectList() {

        let loading = this.$loading.show();

      let body = {
        limit: this.paginationConfig.pageSize,
        offset:
          this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
        search_value: {
          // ...this.search_value,
          // letter_project_begin: DateFunction.getSearchDate(
          //   this.search_value.letter_project_begin
          // ),
          // letter_project_end: DateFunction.getSearchDate(
          //   this.search_value.letter_project_end
          // ),
        },
        
      };


      
        const { data } = await HTTP.post("/getApproveReject", body);

        this.id = data.id;
        this.letter_code = data.letter_code;
        this.letter_date = data.letter_date;
        this.letter_topic = data.letter_topic;
        this.letter_type = data.letter_type;

        if (data.data && data.data.length > 0) {
          this.dataValues = data.data;
          this.paginationConfig.total = data.total;
          
          loading.hide();
        } else {
          loading.hide();
          this.dataValues = [];
          this.paginationConfig.total = 0;
        }
        loading.hide();
      },
      onClickRows(data) {
        if(data.letter_type =="ขอเบิกเงินโครงการวิจัย"){
          this.$router.push(`/research-details-reject/${data.id}`);
        }else if(data.letter_type =="หนังสือมอบอำนาจ"){
          this.$router.push(`/publications-details-reject/${data.id}`);
        }else if(data.letter_type =="หนังสือเชิญเข้าร่วม/ประชุม"){
          this.$router.push(`/authorizes-details-reject/${data.id}`);
        }else if(data.letter_type =="ขอรับค่าตีพิมพ์ผลงาน"){
          this.$router.push(`/published-details-reject/${data.id}`);
        }
        
        
      },

      handlePageChange(page) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: page,
        };
        this.fetchResearchList();
      },

      handlePageSizeChange(current, pageSize) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: current,
          pageSize: pageSize,
        };
      },

    },

    data() {
      return {
        loader: "",
        search_value: null,
        sortOptions: 0,

        columns: [
          {
            title: "เลขที่หนังสือ/ลงวันที่",
            dataIndex: "letter_code",
            width: 150,
          },
          {
            title: "ชื่อหนังสือ/บันทึก",
            dataIndex: "letter_topic",
            width: 250,
          },
          {
            title: "ประเภทหนังสือ/บันทึก",
            dataIndex: "letter_type",
            width: 150,
          },
          {
            title: "สถานะ",
            dataIndex: "approve_status",
            align: "center",
            width: 80,
          },
        ],
     
        paginationConfig: {
          total: 0,
          pageSize: 10,
          current: 1,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 50, 100],
          onChange: this.handlePageChange,
          onShowSizeChange: this.handlePageSizeChange,
        },

        dataValues: [],
        letter_project_topic: [],
        letter_project_begin: [],
        letter_project_end: [],
        research_name: [],
        dataLength: 0,
        
      };
    },
    

    
  };
  </script>
  
  <style scoped></style>