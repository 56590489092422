<template>
  <div class="flex flex-start justify-center flex-col gap-1">
    <div class="flex">
      <label :for="name" v-if="label">{{ label }}</label>
      <p v-if="isRequire" class="ml-1" :style="{ color: '#ED4040' }">*</p>
    </div>
    <a-auto-complete
      :placeholder="placeholder"
      :value="labelShow"
      :options="options"
      :filter-option="filterOption"
      @change="(e, v) => handleChange(e, v)"
      @select="(e, v) => (labelShow = v.label)"
      notFoundContent="ไม่พบข้อมูล"
      allow-clear
      :max-results="5"
    />
  </div>
</template>
<!-- @select="(e, v) => (labelShow = v.label)" -->
<script>
export default {
  props: {
    label: {
      Type: String,
    },
    name: {
      Type: String,
    },
    id: {
      Type: String,
    },
    placeholder: {
      Type: String,
    },
    type: {
      Type: String,
      default: "text",
    },
    classList: {
      Type: String,
    },
    isRequire: {
      Type: Boolean,
      default: false,
    },
    prefix: {
      Type: String,
    },
    suffix: {
      Type: String,
    },
    onClicked: {
      Type: Function,
    },
    error: {
      Type: String,
    },
    options: {
      Type: Array,
      default: () => [],
    },
    optionValue: {
      Type: String,
    },
    onChanged: {
      Type: Function,
      default: () => {},
    },
  },

  watch: {
    optionValue(newValue) {
      if (newValue === "") {
        this.labelShow = "";
      }
    },
  },

  methods: {
    handleChange(e, v) {
      this.labelShow = e;
      this.onChanged(e);
    },

    filterOption(inputValue, option) {
      if (!inputValue || !option || !option.label) {
        return false;
      }

      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    handleSelect(value) {
      // เมื่อมีการเลือกข้อมูล
      this.$emit('handleSelect', value); // ส่งค่าที่เลือกกลับไปยังพาเรนต์
    }
  },

  data() {
    return {
      labelShow: "",
      searchOptions: null,
      selectedValue: null, // เก็บข้อมูลที่ถูกเลือก
    };
  },
};
</script>
