<template>
    <div class="flex flex-wrap">
        <div class="w-full bg-white p-4 boxShadow-main" :style="{ borderRadius: '10px' }">
            <form @submit.prevent="submitForm">
                <a-row :gutter="[16]">
                    <a-col :lg="24" :md="24" :xs="24" class="gutter-row">

                        <a-row :gutter="[16, 16]">
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนหัวของหนังสือมอบอำนาจ</b></a-col>
                            <a-col :lg="6" :md="6" :xs="24">
                                <TextInputUCVue :v-model="letter_code" :label="'เลขที่หนังสือ'"
                                    :placeholder="'เลขที่หนังสือ'" :textValues="Values.letter_code"
                                    :onChanged="(v) => (this.Values.letter_code = v)" />
                            </a-col>
                            <a-col :lg="18" :md="18" :xs="24">
                                <label>มอบอำนาจเกี่ยวกับ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="power_topic" 
                                    :placeholder="'ระบุมอบอำนาจเกี่ยวกับ'" :textValues="Values.power_topic"
                                    :onChanged="(v) => (this.Values.power_topic = v)" />
                                    <span v-if="errors.power_topic" class="error-message">{{ errors.power_topic }}</span>
                            </a-col>

                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนเนื้อหาของบันทึกข้อความ</b></a-col>
                            <a-col :lg="12" :md="12" :xs="12">
                                <label>ชื่อผู้รับมอบอำนาจ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="power_for"
                                    :placeholder="'ระบุชื่อผู้รับมอบอำนาจ'" :textValues="Values.power_for"
                                    :onChanged="(v) => (this.Values.power_for = v)" />
                                    <span v-if="errors.power_for" class="error-message">{{ errors.powerpower_for_topic }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ตำแหน่ง<span class="text-danger">*</span></label>
                                <AutoCompleteUC :v-model="econ_report_position_id" 
                                    placeholder="ระบุตำแหน่ง" :options="econ_report_position_id"
                                    :onChanged="(v) => (Values.econ_report_position_id = v)"
                                    :optionValue="Values.econ_report_position_id" />
                                    <span v-if="errors.econ_report_position_id" class="error-message">{{ errors.econ_report_position_id }}</span>
                            </a-col>
                            <!-- ตรงนีต้องแก้ไขให้เป็น Multiple Selected (เลือกได้มากกว่า 1) -->
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>เป็นผู้มีอำนาจในการ<span class="text-danger">*</span></label>
                                <!-- <AutoCompleteUC :v-model="econ_report_power_id" 
                                    placeholder="ระบุเป็นผู้มีอำนาจในการ" :options="econ_report_power_id"
                                    :onChanged="(v) => (Values.econ_report_power_id = v)"
                                    :optionValue="Values.econ_report_power_id" /> -->
                                    <a-tree-select
                                    :v-model="econ_report_power_id" 
                                    :tree-data="econ_report_power_id"
                                    tree-checkable
                                    allow-clear
                                    :show-checked-strategy="SHOW_PARENT"
                                    :placeholder="'ระบุเป็นผู้มีอำนาจในการ'"
                                    tree-node-filter-prop="label"
                                    class="w-full"
                                    :options="econ_report_power_id"
                                    @change="onChanged"     
                                  ></a-tree-select>
                                    <span v-if="errors.econ_report_power_id" class="error-message">{{ errors.econ_report_power_id }}</span>
                            </a-col>
                            <!-- ตรงนีต้องแก้ไขให้เป็น Multiple Selected (เลือกได้มากกว่า 1) -->
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>ภายใต้โครงการ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="power_project" 
                                    :placeholder="'ภายใต้โครงการ'" :textValues="Values.power_project"
                                    :onChanged="(v) => (this.Values.power_project = v)" />
                                    <span v-if="errors.power_project" class="error-message">{{ errors.power_project }}</span>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนท้ายของหนังสือมอบอำนาจ</b></a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ผู้ลงนามหนังสือมอบอำนาจ<span class="text-danger">*</span></label>
                                <AutoCompleteUC :v-model="letter_sign_id"
                                    placeholder="ระบุผู้ลงนามหนังสือมอบอำนาจ" :options="letter_sign_id"
                                    :onChanged="(v) => (Values.letter_sign_id = v)"
                                    :optionValue="Values.letter_sign_id" />
                                    <span v-if="errors.letter_sign_id" class="error-message">{{ errors.letter_sign_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ตำแหน่งผู้ลงนามหนังสือมอบอำนาจ<span class="text-danger">*</span></label>
                                <AutoCompleteUC :v-model="letter_sign_position_id"
                                    placeholder="ระบุตำแหน่งผู้ลงนามหนังสือมอบอำนาจ" :options="letter_sign_position_id"
                                    :onChanged="(v) => (Values.letter_sign_position_id = v)"
                                    :optionValue="Values.letter_sign_position_id" />
                                    <span v-if="errors.letter_sign_position_id" class="error-message">{{ errors.letter_sign_position_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>วิธีการลงนาม<span class="text-danger">*</span></label>
                                <AutoCompleteUC :v-model="power_action_id" 
                                    placeholder="ระบุวิธีการลงนาม" :options="power_action_id"
                                    :onChanged="(v) => (Values.power_action_id = v)"
                                    :optionValue="Values.power_action_id" />
                                    <span v-if="errors.power_action_id" class="error-message">{{ errors.power_action_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                               
                                <DatePickkerUCVue :label="'วันที่ลงนามในคำสั่ง (ถ้ามี)'" 
                                :dueDateValue="letter_sign_date"
                                    :onChangeDate="(v) => (this.Values.letter_sign_date = v)"
                                
                                />
                            </a-col>


                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>เอกสารแนบ (ถ้ามี)</b>
                                <p :style="{ fontSize: '14px', opacity: '0.6' }">
                                    ขนาดไฟล์ไม่เกิน 8 MB
                                </p>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange1" />
                                    เอกสารแนบ 1 (ถ้ามี) : <span v-if="selectedFile1" style="color: #4691f6;">{{
                                        selectedFile1.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />

                                </label>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange2" />
                                    เอกสารแนบ 2 (ถ้ามี) : <span v-if="selectedFile2" style="color: #4691f6;">{{
                                        selectedFile2.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />
                                </label>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange3" />
                                    เอกสารแนบ 3 (ถ้ามี) : <span v-if="selectedFile3" style="color: #4691f6;">{{
                                        selectedFile3.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />
                                </label>
                            </a-col>

                        </a-row>
                    </a-col>

                </a-row>

                <a-row :gutter="[16]">
                    <a-col :lg="8" :md="12" :xs="24" class="gutter-row">
                        <a-row :gutter="[16, 16]" class="h-full">
                            <a-col :md="12">
                                <ButtonUCVue type="submit" :header="'test'" :label="'บันทึกข้อมูล'" :isBg="true"
                                    :iconName="'SaveIcon'" :iconColor="'#FFF'" />
                            </a-col>
                            <!-- <a-col :md="12">
                                <ButtonUCVue header="'header'" :label="'ล้างค่า'" :onClicked="() => handleClear()"
                                    :iconName="'refreshIcon'" :iconColor="'#ED4081'" />
                            </a-col> -->
                        </a-row>
                    </a-col>
                </a-row>
            </form>
        </div>
        <!-- Button Actions -->
        <div class="w-full bg-white mt-2 p-4 boxShadow-main flex gap-4 items-center justify-end sticky bottom-0"
            :style="{ borderRadius: '10px', height: '100%' }">
            <div :style="{ minWidth: '10rem' }">
                <ButtonUCVue label="ย้อนกลับ" :onClicked="backPage" />
            </div>
        </div>
        <!-- <button @click="checkFormData">Check</button> -->
    </div>
</template>
<script>
import ButtonUCVue from "../../components/ButtonUC.vue";
import TextInputUCVue from "../../components/TextInputUC.vue";
import AutoCompleteUC from "../../components/AutoCompleteUC.vue";
import DatePickkerUCVue from "../../components/DatePickkerUC.vue";
// import TreeSelectProject from "../../components/TreeSelectProject.vue";
import BaseIcon from "../../components/Icon/BaseIcon.vue";

import { TreeSelect } from "ant-design-vue";
import { HTTP } from "../../http-common";


import dayjs from "dayjs";
import moment from "moment";


export default {
    name: "ReportSignPositionAdd",
    components: {
        TextInputUCVue,
        ButtonUCVue,
        AutoCompleteUC,
        DatePickkerUCVue,
        BaseIcon,
        // TreeSelectProject,


    },

    mounted() {

        this.fetchSignList();
        //this.$store.commit("setBread", this.$router.currentRoute._value);
        this.$store.commit("setChild", this.$router.currentRoute._value);
    },


    // mounted() {
    //     if (this.$router.currentRoute._rawValue.params.project_id) {
    //     this.project_id = this.$router.currentRoute._rawValue.params.project_id;
    //     this.getResearchById(this.project_id);
    //     }
    //     this.$store.commit("setChild", this.$router.currentRoute._value);
    // },

    unmounted() {
        //this.$store.commit("setClearFileSave");
        this.$store.commit("setChild", "");
    },

    methods: {
        async checkFormData(){
        // console.log(this.ReportData);
        console.log(this.Values);
       },
       onChanged(value) {
        this.Values.econ_report_power_id = value;
        // console.log('Selected value:', value);  // แสดงค่าที่ถูกเลือกใน console
        },
        handleFileChange1(event) {
            this.Values.attach_1 = event.target.files[0];
            this.selectedFile1 = this.Values.attach_1;
        },
        handleFileChange2(event) {
            this.Values.attach_2 = event.target.files[0];
            this.selectedFile2 = this.Values.attach_2;
        },
        handleFileChange3(event) {
            this.Values.attach_3 = event.target.files[0];
            this.selectedFile3 = this.Values.attach_3;
        },
        async changedDate(date) {
            this.Values = {
                ...this.Values,
                letter_project_begin: date.startDate,
                letter_project_end: date.endDate,
            };
        },
        async fetchSignList() {
            let loading = this.$loading.show();
            const { data } = await HTTP.post("/getReportSignList");
            this.letter_sign_id = data.sign_id;
            this.letter_sign_position_id = data.sign_position_id;
            this.econ_report_position_id = data.econ_report_position_id;
            this.econ_report_power_id = data.econ_report_power_id;
            this.power_action_id = data.power_action_id;

            loading.hide();
        },

        async backPage() {
            this.$router.push(`/report-publications`);
        },
        async handleClear() {
            this.Values = {
                letter_code: "",
                power_topic: "",
                power_for: "",
                econ_report_position_id: "",
                econ_report_power_id: "",
                power_project: "",
                power_action_id: "",
                letter_sign_id: "",
                letter_sign_position_id: "",
                letter_sign_date: "",

            };

        },
        async submitForm() {

            const formData = new FormData();

            formData.append("letter_code", this.Values.letter_code);
            formData.append("power_topic", this.Values.power_topic);
            formData.append("power_for", this.Values.power_for);
            formData.append("econ_report_position_id", this.Values.econ_report_position_id);
            formData.append("econ_report_power_id", this.Values.econ_report_power_id);
            formData.append("power_project", this.Values.power_project);
            formData.append("power_action_id", this.Values.power_action_id);
            formData.append("letter_sign_id", this.Values.letter_sign_id);
            formData.append("letter_sign_position_id", this.Values.letter_sign_position_id);
            formData.append("letter_sign_date", moment(new Date(this.Values.letter_sign_date)).format("YYYY-MM-DD"));
           
            /* has :: attach_1 */
            if (this.Values.attach_1) {
                formData.append("attach_1", this.Values.attach_1);
            } else {
                console.log('No attach_1 file selected.');
            }

            /* has :: attach_2 */
            if (this.Values.attach_2) {
                formData.append("attach_2", this.Values.attach_2);
            } else {
                console.log('No attach_2 file selected.');
            }

            /* has :: attach_3 */
            if (this.Values.attach_3) {
                formData.append("attach_3", this.Values.attach_3);
            } else {
                console.log('No attach_3 file selected.');
            }

            try {

                let isValid = true;
                if (!this.Values.power_topic) {
                    this.errors.power_topic = 'ระบุมอบอำนาจเกี่ยวกับ';
                    isValid = false;
                }
                if (!this.Values.power_for) {
                    this.errors.power_for = 'ระบุชื่อผู้รับมอบอำนาจ';
                    isValid = false;
                }
                if (!this.Values.econ_report_position_id) {
                    this.errors.econ_report_position_id = 'ระบุตำแหน่ง';
                    isValid = false;
                }
                if (!this.Values.econ_report_power_id) {
                    this.errors.econ_report_power_id = 'ระบุเป็นผู้มีอำนาจในการ';
                    isValid = false;
                }
                if (!this.Values.power_project) {
                    this.errors.power_project = 'ระบุภายใต้โครงการ';
                    isValid = false;
                }
                if (!this.Values.letter_sign_id) {
                    this.errors.letter_sign_id = 'ระบุผู้ลงนามบันทึกข้อความ';
                    isValid = false;
                }
                if (!this.Values.letter_sign_position_id) {
                    this.errors.letter_sign_position_id = 'ระบุตำแหน่งผู้ลงนามบันทึกข้อความ';
                    isValid = false;
                }
                if (!this.Values.power_action_id) {
                    this.errors.power_action_id = 'ระบุวิธีการลงนาม';
                    isValid = false;
                }
                if (isValid) {

                await HTTP.post('/InsertReportPublications', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {

                        this.$swal.fire({
                            title: "บันทึกสำเร็จ",
                            width: 300,
                            height: 300,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                        }).then((result) => {
                            this.$router.push(`/report-publications`);
                        });
                    })
                    .catch(error => {
                        alert('Error uploading file:' + error);
                    });
                }else{
                    console.error('There was an validate!', error);
                }


            } catch (error) {
                console.error('There was an error!', error);
            }

        },

    },

    data() {
        return {
            SHOW_PARENT: TreeSelect.SHOW_PARENT,
            econ_report_power_id: [],
            errors: {},      // เก็บ error message ของ validation
            selectedFile1: null,
            selectedFile2: null,
            selectedFile3: null,
            loader: "",
            letter_sign_id: [],
            letter_sign_position_id: [],
            econ_report_position_id: [],
            power_action_id: [],
            Values: {

                letter_code: null,
                power_topic: "",
                power_for: "",
                econ_report_position_id: "",
                power_project: "",
                power_action_id: "",
                letter_sign_id: "",
                letter_sign_position_id: "",
                letter_sign_date: null,
                attach_1: "",
                attach_2: "",
                attach_3: "",

            },
        };
    },



};
</script>

<style scoped>
.text-danger{
    color:red;
}
.error-message{
    color:red;
    font-size: 12px;
}
.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border: 2px solid #4CAF50;
    border-radius: 50px;
    border-color: #ddd;
    color: ddd;
    font-weight: bold;
    width: 100%;
}

.custom-file-upload input[type="file"] {
    display: none;
}

button {
    margin-top: 10px;
    padding: 6px 12px;
    border: none;
    background-color: #2196F3;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0b7dda;
}
</style>