<template>
    <div class="flex flex-wrap">
        <div class="w-full bg-white p-4 boxShadow-main" :style="{ borderRadius: '10px' }">
            <form @submit.prevent="submitForm">
                <a-row :gutter="[16]">
                    <a-col :lg="24" :md="24" :xs="24" class="gutter-row">

                        <a-row :gutter="[16, 16]">
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนหัวของหนังสือเชิญ</b></a-col>
                            <a-col :lg="6" :md="6" :xs="24">
                                <a-col :lg="24" :md="24" :xs="24"><label>ประเภทหนังสือ<span class="text-danger">*</span></label></a-col>
                                <label class="ml-2">
                                    <input type="radio" name="letter_source_id" :v-model="letter_source_id" value="0"
                                    @change="handleRadioChange($event)"  >
                                    หนังสือภายใน
                                </label><br>
                                <label class="ml-2">
                                    <input type="radio" name="letter_source_id" :v-model="letter_source_id" value="1"
                                    @change="handleRadioChange($event)" >
                                    หนังสือภายนอก
                                </label>
                                <span v-if="errors.letter_source_id" class="error-message">{{ errors.letter_source_id }}</span>
                            </a-col>
                            <a-col :lg="9" :md="9" :xs="24">
                                <TextInputUCVue :v-model="letter_code" :label="'เลขที่หนังสือ'"
                                    :placeholder="'เลขที่หนังสือ'" :textValues="Values.letter_code"
                                    :onChanged="(v) => (this.Values.letter_code = v)"  required/>
                            </a-col>
                            <a-col :lg="9" :md="9" :xs="24">
                                <DatePickkerUCVue :label="'วันที่หนังสือ'" :dueDateValue="stepDueDate" :onChangeDate="(value) => {
                                    new_step_end_date = true;
                                    stepDueDate = value;
                                }" />
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>เรียน<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="letter_to" :placeholder="'เรียน'"
                                    :textValues="Values.letter_to" :onChanged="(v) => (this.Values.letter_to = v)" />
                                    <span v-if="errors.letter_to" class="error-message">{{ errors.letter_to }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>เรื่องที่เชิญ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="letter_topic"
                                    :placeholder="'เรื่องที่เชิญ'" :textValues="Values.letter_topic"
                                    :onChanged="(v) => (this.Values.letter_topic = v)" />
                                    <span v-if="errors.letter_topic" class="error-message">{{ errors.letter_topic }}</span>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนเนื้อหาของหนังสือเชิญ</b></a-col>
                            <a-col :lg="24" :md="24" :xs="24">
                                <label>ชื่องานวิจัย<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="letter_project_topic" 
                                    :placeholder="'ระบุชื่องานวิจัย'" :textValues="Values.letter_project_topic"
                                    :onChanged="(v) => (this.Values.letter_project_topic = v)" />
                                    <span v-if="errors.letter_project_topic" class="error-message">{{ errors.letter_project_topic }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ชื่อหัวหน้าโครงการ<span class="text-danger">*</span></label>
                                <AutoCompleteUC placeholder="ระบุชื่อหัวหน้าโครงการ"
                                    :options="letter_project_head" :onChanged="(v) => (Values.letter_project_head = v)"
                                    :optionValue="Values.letter_project_head" />
                                    <span v-if="errors.letter_project_head" class="error-message">{{ errors.letter_project_head }}</span>
                            </a-col>

                            <a-col :lg="12" :md="12" :xs="24">
                                <label>แหล่งทุน<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="letter_project_source"
                                    :placeholder="'แหล่งทุน'" :textValues="Values.letter_project_source"
                                    :onChanged="(v) => (this.Values.letter_project_source = v)" />
                                    <span v-if="errors.letter_project_source" class="error-message">{{ errors.letter_project_source }}</span>
                            </a-col>

                            <a-col :lg="12" :md="12" :xs="24" class="gutter-row">
                                <RangePickkerUCVue :onChanged="changedDate" :startedDate="Values.letter_project_begin"
                                    :endedDate="Values.letter_project_end" />
                                    <span v-if="errors.letter_project_end" class="error-message">{{ errors.letter_project_end }}</span>
                            </a-col>
                            <a-col :lg="4" :md="4" :xs="24">
                                <TextInputUCVue :v-model="letter_project_day" :label="'ระยะเวลาโครงการ (วัน)'"
                                    :placeholder="'จำนวนวัน'" :textValues="Values.letter_project_day"
                                    :onChanged="(v) => (this.Values.letter_project_day = v)" />
                            </a-col>
                            <a-col :lg="4" :md="4" :xs="24">
                                <TextInputUCVue :v-model="letter_project_month" :label="'ระยะเวลาโครงการ (เดือน)'"
                                    :placeholder="'จำนวนเดือน'" :textValues="Values.letter_project_month"
                                    :onChanged="(v) => (this.Values.letter_project_month = v)" />
                            </a-col>
                            <a-col :lg="4" :md="4" :xs="24">
                                <TextInputUCVue :v-model="letter_project_year" :label="'ระยะเวลาโครงการ (ปี)'"
                                    :placeholder="'จำนวนปี'" 
                                    :onChanged="(v) => (this.Values.letter_project_year = v)" />
                            </a-col>


                            <!-- น้องกล้วยทำตรงนี้ต่อ 2024-09-04 -->
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ชื่อผู้ถูกเรียนเชิญ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="letter_invited" 
                                    :placeholder="'ระบุชื่อผู้ถูกเรียนเชิญ'" :textValues="Values.letter_invited"
                                    :onChanged="(v) => (this.Values.letter_invited = v)" />
                                    <span v-if="errors.letter_invited" class="error-message">{{ errors.letter_invited }}</span>
                            </a-col> <!-- econ_report_3 >> letter_invited -->
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>สังกัดผู้ถูกเรียนเชิญ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="letter_invited_organization" 
                                    :placeholder="'ระบุสังกัดผู้ถูกเรียนเชิญ'"
                                    :onChanged="(v) => (this.Values.letter_invited_organization = v)" />
                                    <span v-if="errors.letter_invited_organization" class="error-message">{{ errors.letter_invited_organization }}</span>
                            </a-col> <!-- econ_report_3 >> letter_invited_organization -->

                            <a-col :lg="4" :md="4" :xs="24">
                                <label>ลักษณะที่เชิญ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="conference_action" 
                                    :placeholder="'ตัวอย่างเช่น เข้าร่วมประชุม'" :textValues="Values.conference_action"
                                    :onChanged="(v) => (this.Values.conference_action = v)" />
                                    <span v-if="errors.conference_action" class="error-message">{{ errors.conference_action }}</span>
                            </a-col> <!-- econ_report_3 >> conference_action -->
                            <a-col :lg="4" :md="4" :xs="24">
                                <label>วันที่เชิญ<span class="text-danger">*</span></label>
                                <DatePickkerUCVue  :dueDateValue="stepDueDate2" :onChangeDate="(value) => {
                                    new_step_end_date = true;
                                    stepDueDate2 = value;
                                }" />
                                <span v-if="errors.conference_date" class="error-message">{{ errors.conference_date }}</span>
                            </a-col>
                            <a-col :lg="4" :md="4" :xs="24">
                                <label>เวลาที่เชิญ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="conference_time" 
                                    :placeholder="'เวลาที่เชิญ'" :textValues="Values.conference_time"
                                    :onChanged="(v) => (this.Values.conference_time = v)" />
                                    <span v-if="errors.conference_time" class="error-message">{{ errors.conference_time }}</span>
                            </a-col> <!-- econ_report_3 >> conference_time -->
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>สถานที่<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="conference_place" 
                                    :placeholder="'ระบุสถานที่'" :textValues="Values.conference_place"
                                    :onChanged="(v) => (this.Values.conference_place = v)" />
                                    <span v-if="errors.conference_place" class="error-message">{{ errors.conference_place }}</span>
                            </a-col> <!-- econ_report_3 >> conference_place -->

                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <a-col :lg="24" :md="24" :xs="24">สิ่งที่แนบมาด้วย<span class="text-danger">*</span></a-col>
                                <textarea v-model="letter_attach" id="letter_attach" placeholder="ตัวอย่างเช่น 
                                    1. ข้อเสนอโครงการวิจัย จำนวน ๑ ฉบับ
                                    2. สัญญารับทุนอุดหนุนโครงการวิจัย จำนวน ๑ ฉบับ" style="width: 100%;"
                                    rows="5" @change="handleChange"></textarea>
                                    <span v-if="errors.letter_attach" class="error-message">{{ errors.letter_attach }}</span>
                            </a-col> <!-- econ_report_3 >> letter_attach -->
                            <!-- น้องกล้วยทำตรงนี้ต่อ 2024-09-04 -->


                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนท้ายของบันทึกข้อความ</b></a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ผู้ลงนามหนังสือเชิญ<span class="text-danger">*</span></label>
                                <AutoCompleteUC :v-model="letter_sign_id"
                                    placeholder="ระบุผู้ลงนามหนังสือเชิญ" :options="letter_sign_id"
                                    :onChanged="(v) => (Values.letter_sign_id = v)"
                                    :optionValue="Values.letter_sign_id" />
                                    <span v-if="errors.letter_sign_id" class="error-message">{{ errors.letter_sign_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ตำแหน่งผู้ลงนามหนังสือเชิญ<span class="text-danger">*</span></label>
                                <AutoCompleteUC :v-model="letter_sign_position_id"
                                    placeholder="ระบุตำแหน่งผู้ลงนามหนังสือเชิญ" :options="letter_sign_position_id"
                                    :onChanged="(v) => (Values.letter_sign_position_id = v)"
                                    :optionValue="Values.letter_sign_position_id" />
                                    <span v-if="errors.letter_sign_position_id" class="error-message">{{ errors.letter_sign_position_id }}</span>
                            </a-col>

                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>เอกสารแนบ (ถ้ามี)</b>
                                <p :style="{ fontSize: '14px', opacity: '0.6' }">
                                    ขนาดไฟล์ไม่เกิน 8 MB
                                </p>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange1" />
                                    เอกสารแนบ 1 (ถ้ามี) : <span v-if="selectedFile1" style="color: #4691f6;">{{
                                        selectedFile1.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />

                                </label>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange2" />
                                    เอกสารแนบ 2 (ถ้ามี) : <span v-if="selectedFile2" style="color: #4691f6;">{{
                                        selectedFile2.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />
                                </label>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange3" />
                                    เอกสารแนบ 3 (ถ้ามี) : <span v-if="selectedFile3" style="color: #4691f6;">{{
                                        selectedFile3.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />
                                </label>
                            </a-col>

                        </a-row>
                    </a-col>

                </a-row>

                <a-row :gutter="[16]">
                    <a-col :lg="8" :md="12" :xs="24" class="gutter-row">
                        <a-row :gutter="[16, 16]" class="h-full">
                            <a-col :md="12">
                                <ButtonUCVue type="submit" :header="'test'" :label="'บันทึกข้อมูล'" :isBg="true"
                                    :iconName="'SaveIcon'" :iconColor="'#FFF'" />
                            </a-col>
                            <!-- <a-col :md="12">
                                <ButtonUCVue header="'header'" :label="'ล้างค่า'" :onClicked="() => handleClear()"
                                    :iconName="'refreshIcon'" :iconColor="'#ED4081'" />
                            </a-col> -->
                        </a-row>
                    </a-col>
                </a-row>
            </form>
        </div>
        <!-- Button Actions -->
        <div class="w-full bg-white mt-2 p-4 boxShadow-main flex gap-4 items-center justify-end sticky bottom-0"
            :style="{ borderRadius: '10px', height: '100%' }">
            <div :style="{ minWidth: '10rem' }">
                <ButtonUCVue label="ย้อนกลับ" :onClicked="backPage" />
            </div>
        </div>
        <!-- <button @click="checkFormData">Check</button> -->
    </div>
</template>
<script>
import ButtonUCVue from "../../components/ButtonUC.vue";
import TextInputUCVue from "../../components/TextInputUC.vue";
import RangePickkerUCVue from "../../components/RangePickkerUC.vue";
import AutoCompleteUC from "../../components/AutoCompleteUC.vue";
import DatePickkerUCVue from "../../components/DatePickkerUC.vue";
import BaseIcon from "../../components/Icon/BaseIcon.vue";



import { HTTP } from "../../http-common";


import dayjs from "dayjs";
import moment from "moment";


export default {
    name: "ReportSignPositionAdd",
    components: {
        TextInputUCVue,
        ButtonUCVue,
        RangePickkerUCVue,
        AutoCompleteUC,
        DatePickkerUCVue,
        BaseIcon,


    },
   
    mounted() {

        this.fetchSignList();

        //this.$store.commit("setBread", this.$router.currentRoute._value);
        this.$store.commit("setChild", this.$router.currentRoute._value);
    },


    // mounted() {
    //     if (this.$router.currentRoute._rawValue.params.project_id) {
    //     this.project_id = this.$router.currentRoute._rawValue.params.project_id;
    //     this.getResearchById(this.project_id);
    //     }
    //     this.$store.commit("setChild", this.$router.currentRoute._value);
    // },

    unmounted() {
        //this.$store.commit("setClearFileSave");
        this.$store.commit("setChild", "");
    },

    methods: {
        async handleChange(event){
            console.log('Content changed:', event.target.value);
            let textarea_value = event.target.value;
            this.Values.letter_attach = textarea_value;
        },
        async checkFormData(){
        // console.log(this.ReportData);
        // console.log(this.Values);
       },
        handleFileChange1(event) {
            this.Values.attach_1 = event.target.files[0];
            this.selectedFile1 = this.Values.attach_1;
        },
        handleFileChange2(event) {
            this.Values.attach_2 = event.target.files[0];
            this.selectedFile2 = this.Values.attach_2;
        },
        handleFileChange3(event) {
            this.Values.attach_3 = event.target.files[0];
            this.selectedFile3 = this.Values.attach_3;
        },
        handleRadioChange(event) {
            // จัดการการเปลี่ยนแปลงของ radio button
            this.Values.letter_source_id = event.target.value;
            // alert(`Radio button selected: `+event.target.value);
           
        },
        async changedDate(date) {
            this.Values = {
                ...this.Values,
                letter_project_begin: date.startDate,
                letter_project_end: date.endDate,
            };
        },
        async fetchSignList() {
            let loading = this.$loading.show();
            const { data } = await HTTP.post("/getReportSignList");
            this.letter_sign_id = data.sign_id;
            this.letter_sign_position_id = data.sign_position_id;
            this.letter_project_head = data.research_name;
            loading.hide();
        },

        async backPage() {
            this.$router.push(`/report-authorizes`);
        },
        async handleClear() {
            this.Values = {
                letter_source_id: "",
                letter_code: null,
                letter_date: "",
                letter_topic: "",
                letter_to: "",
                letter_project_topic: "",
                letter_project_begin: "",
                letter_project_end: "",
                letter_project_year: "",
                letter_project_month: "",
                letter_project_day: "",
                letter_project_budget: "",
                letter_project_source: "",
                letter_project_head: "",
                letter_money: "",
                letter_money_round: "",
                letter_sign_id: "",
                letter_sign_position_id: "",


            };

        },
        async submitForm() {


            const formData = new FormData();

            formData.append("letter_source_id", this.Values.letter_source_id);
            formData.append("letter_code", this.Values.letter_code);
            formData.append("letter_date", moment(new Date(this.stepDueDate)).format("YYYY-MM-DD"));
            formData.append("letter_topic", this.Values.letter_topic);
            formData.append("letter_to", this.Values.letter_to);
            formData.append("letter_project_topic", this.Values.letter_project_topic);
            formData.append("letter_project_begin", moment(new Date(this.Values.letter_project_begin))
                .format("YYYY-MM-DD"));
            formData.append("letter_project_end", moment(new Date(this.Values.letter_project_end))
                .format("YYYY-MM-DD"));
            formData.append("letter_project_year", this.Values.letter_project_year);
            formData.append("letter_project_month", this.Values.letter_project_month);
            formData.append("letter_project_day", this.Values.letter_project_day);
            formData.append("letter_project_source", this.Values.letter_project_source);
            formData.append("letter_project_head", this.Values.letter_project_head);
            formData.append("letter_sign_id", this.Values.letter_sign_id);
            formData.append("letter_sign_position_id", this.Values.letter_sign_position_id);
            formData.append("letter_invited", this.Values.letter_invited);
            formData.append("letter_invited_organization", this.Values.letter_invited_organization);
            formData.append("conference_action", this.Values.conference_action);
            // formData.append("conference_date", moment(new Date(this.stepDueDate)).format("YYYY-MM-DD"));
            formData.append("conference_date", moment(new Date(this.stepDueDate2)).format("YYYY-MM-DD"));
            formData.append("conference_time", this.Values.conference_time);
            formData.append("conference_place", this.Values.conference_place);
            formData.append("letter_attach", this.Values.letter_attach);

            /* has :: attach_1 */
            if (this.Values.attach_1) {
                formData.append("attach_1", this.Values.attach_1);
            } else {
                console.log('No attach_1 file selected.');
            }

            /* has :: attach_2 */
            if (this.Values.attach_2) {
                formData.append("attach_2", this.Values.attach_2);
            } else {
                console.log('No attach_2 file selected.');
            }

            /* has :: attach_3 */
            if (this.Values.attach_3) {
                formData.append("attach_3", this.Values.attach_3);
            } else {
                console.log('No attach_3 file selected.');
            }




                    try {

                        
                this.errors = {};
                let isValid = true;


                if (!this.Values.letter_source_id) {
                    this.errors.letter_source_id = 'ระบุประเภทหนังสือ';
                    isValid = false;
                }
                if (!this.Values.letter_to) {
                    this.errors.letter_to = 'ระบุเรียน';
                    isValid = false;
                }
                if (!this.Values.letter_topic) {
                    this.errors.letter_topic = 'ระบุเรื่องที่เชิญ';
                    isValid = false;
                }
                if (!this.Values.letter_project_topic) {
                    this.errors.letter_project_topic = 'ชื่องานวิจัย';
                    isValid = false;
                }
                if (!this.Values.letter_project_head) {
                    this.errors.letter_project_head = 'ระบุชื่อหัวหน้าโครงการ';
                    isValid = false;
                }
                if (!this.Values.letter_project_source) {
                    this.errors.letter_project_source = 'ระบุแหล่งทุน';
                    isValid = false;
                }
                if (!this.Values.letter_project_end) {
                    this.errors.letter_project_end = 'ระบุวันที่เริ่มต้นและสิ้นสุดโครงการ';
                    isValid = false;
                }
                if (!this.Values.letter_invited) {
                    this.errors.letter_invited = 'ระบุชื่อผู้ถูกเรียนเชิญ';
                    isValid = false;
                }
                if (!this.Values.letter_invited_organization) {
                    this.errors.letter_invited_organization = 'ระบุสังกัดผู้ถูกเรียนเชิญ';
                    isValid = false;
                }
                if (!this.Values.conference_action) {
                    this.errors.conference_action = 'ระบุลักษณะที่เชิญ';
                    isValid = false;
                }
                if (!this.stepDueDate2) {
                    this.errors.stepDueDate2 = 'ระบุวันที่เชิญ';
                    isValid = false;
                }
                if (!this.Values.conference_time) {
                    this.errors.conference_time = 'ระบุเวลาที่เชิญ';
                    isValid = false;
                }
                if (!this.Values.conference_place) {
                    this.errors.conference_place = 'ระบุสถานที่';
                    isValid = false;
                }
                if (!this.Values.letter_attach) {
                    this.errors.letter_attach = 'ระบุสิ่งที่แนบมาด้วย';
                    isValid = false;
                }
                if (!this.Values.letter_sign_id) {
                    this.errors.letter_sign_id = 'ระบุผู้ลงนามหนังสือเชิญ';
                    isValid = false;
                }
                if (!this.Values.letter_sign_position_id) {
                    this.errors.letter_sign_position_id = 'ระบุตำแหน่งผู้ลงนามหนังสือเชิญ';
                    isValid = false;
                }
               
        
                if (isValid) {

                        await HTTP.post('/InsertReportAuthorizes', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                            .then(response => {
                                this.$swal.fire({
                                    title: "บันทึกสำเร็จ",
                                    width: 300,
                                    height: 300,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                }).then((result) => {
                                    this.$router.push(`/report-authorizes`);
                                });
                            })
                            .catch(error => {
                                alert('Error uploading file:' + error);
                            });

                        }else{
                    console.error('There was an validate!', error);
                }

                    } catch (error) {
                        console.error('There was an error!', error);
                    }
                

        },

    },

    data() {
        return {
            errors: {},      // เก็บ error message ของ validation
            selectedFile1: null,
            selectedFile2: null,
            selectedFile3: null,
            loader: "",
            letter_sign_id: [],
            letter_sign_position_id: [],
            letter_project_head: [],
            stepDueDate:null,
            Values: {
                letter_source_id: "",
                letter_code: null,
                letter_date: null,
                letter_topic: "",
                letter_to: "",
                letter_project_topic: "",
                letter_project_begin: "",
                letter_project_end: "",
                letter_project_year: "",
                letter_project_month: "",
                letter_project_day: "",
                letter_project_source: "",
                letter_project_head: "",
                letter_sign_id: "",
                letter_sign_position_id: "",
                letter_invited: "",
                letter_invited_organization: "",
                conference_action: "",
                conference_time: "",
                conference_place: "",
                letter_attach: "",
                attach_1: "",
                attach_2: "",
                attach_3: "",

            },
        };
    },



};
</script>

<style scoped>
.text-danger{
    color:red;
}
.error-message{
    color:red;
    font-size: 12px;
}
.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border: 2px solid #4CAF50;
    border-radius: 50px;
    border-color: #ddd;
    color: ddd;
    font-weight: bold;
    width: 100%;
}

.custom-file-upload input[type="file"] {
    display: none;
}

button {
    margin-top: 10px;
    padding: 6px 12px;
    border: none;
    background-color: #2196F3;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0b7dda;
}

textarea {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border: 2px solid #4CAF50;
    border-color: #ddd;
    color: ddd;
    font-weight: bold;
    width: 100%;
}
</style>