<template>
  <div class="flex flex-wrap">
    <div class="w-full flex flex-col gap-4">
      <div
        class="w-full bg-white p-4 boxShadow-main"
        :style="{ borderRadius: '10px' }"
      >
        <ul class="stepper">
          <swiper
            :modules="modules"
            :slides-per-view="getSwiperSide()"
            :space-between="0"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            class="mySwiper"
          >
            <!-- <swiper-slide
              v-for="(item, idx) in research_approve"
              :key="idx"
              @click="() => stepperClicked(item.approve_index)"
            >
              <div class="stepper__item" :class="getStepperClass(item)">
                <BaseIcon
                  :name="`${item.approve_type}Icon`"
                  :color="getStepperIconColor(item)"
                />
                {{ getStepperTitle(item) }}
              </div>
              <p class="stepper__status">
                วันที่อัปเดตการดำเนินการ : {{ formatDate(item.updated_date) }}
              </p>
            </swiper-slide> -->

            
            <swiper-slide @click="() => stepperClicked(researchData?.id+'-'+1+'-econ_report_2')">
              <div class="stepper__item" :class="getStepperClass(researchData?.status_step_admin,'step_admin')">
                <BaseIcon :name="getStepperTitle(researchData?.status_step_admin,'step_admin')" :color="getStepperIconColor(researchData?.status_step_admin,'step_admin')" />
                Admin
              </div>
              <p class="stepper__status" v-if="researchData?.time_step_admin">วันที่อัปเดตการดำเนินการ : {{ formatDate(researchData?.time_step_admin) }}</p>
            </swiper-slide>
            <swiper-slide  @click="() => stepperClicked(researchData?.id+'-'+2+'-econ_report_2')" >
              <div class="stepper__item" :class="getStepperClass(researchData?.status_step_headadmin,'step_headadmin')">
                <BaseIcon :name="getStepperTitle(researchData?.status_step_headadmin,'step_headadmin')" :color="getStepperIconColor(researchData?.status_step_headadmin,'step_headadmin')"/>
                Headadmin
              </div>
              <p class="stepper__status" v-if="researchData?.time_step_headadmin">วันที่อัปเดตการดำเนินการ : {{ formatDate(researchData?.time_step_headadmin) }}</p>
            </swiper-slide>
            <swiper-slide @click="() => stepperClicked(researchData?.id+'-'+3+'-econ_report_2')">
              <div class="stepper__item" :class="getStepperClass(researchData?.status_step_secretary,'step_secretary')">
                <BaseIcon :name="getStepperTitle(researchData?.status_step_secretary,'step_secretary')" :color="getStepperIconColor(researchData?.status_step_secretary,'step_secretary')"/>
                Secretary
              </div>
              <p class="stepper__status" v-if="researchData?.time_step_secretary">วันที่อัปเดตการดำเนินการ : {{ formatDate(researchData?.time_step_secretary) }}</p>
            </swiper-slide>
            <swiper-slide @click="() => stepperClicked(researchData?.id+'-'+4+'-econ_report_2')">
              <div class="stepper__item" :class="getStepperClass(researchData?.status_step_assocdean,'step_assocdean')">
                <BaseIcon :name="getStepperTitle(researchData?.status_step_assocdean,'step_assocdean')" :color="getStepperIconColor(researchData?.status_step_assocdean,'step_assocdean')"/>
                Assocdean
              </div>
              <p class="stepper__status" v-if="researchData?.time_step_assocdean">วันที่อัปเดตการดำเนินการ : {{ formatDate(researchData?.time_step_assocdean) }}</p>
            </swiper-slide>
            <swiper-slide @click="() => stepperClicked(researchData?.id+'-'+5+'-econ_report_2')">
              <div class="stepper__item" :class="getStepperClass(researchData?.status_step_dean,'step_dean')">
                <BaseIcon :name="getStepperTitle(researchData?.status_step_dean,'step_dean')" :color="getStepperIconColor(researchData?.status_step_dean,'step_dean')"/>
                Dean
              </div>
              <p class="stepper__status" v-if="researchData?.time_step_dean">วันที่อัปเดตการดำเนินการ : {{ formatDate(researchData?.time_step_dean) }}</p>
            </swiper-slide>
          
          </swiper>
          <button class="swiper-button-prev" @click="swiperPrev"></button>
          <button class="swiper-button-next" @click="swiperNext"></button>
        </ul>

        <p class="text-md"  v-if="approver_comment_value!=''">
            {{ approver_comment_value }}
        </p>
        
      </div>

      <a-row :gutter="[16, 16]">
        <a-col :lg="14" :md="24" class="w-full">
          <div
            class="w-full bg-white boxShadow-main flex flex-col"
            :style="{
              borderRadius: '10px',
              height: '100%',
              overflow: 'hidden',
            }"
          >
            <div class="p-4 box-header-color">
              <h1 class="text-lg">ข้อมูลรายงานหนังสือมอบอำนาจ
              </h1>
            </div>

            <div class="flex flex-col gap-4 justify-between h-full p-4">
              <a-row :gutter="[8, 8]">
                <a-col :span="24">
                  <p class="font-semibold text-md"><u>ข้อมูลส่วนหัวของหนังสือมอบอำนาจ</u></p>
                </a-col>
              </a-row>
              <a-row :gutter="[8, 8]" class="align-center">
                <a-col :span="5">
                  <p class="font-semibold text-md">เลขที่หนังสือ</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="18">
                  <a-popover>
                    <p class="text-md w-full break-normal truncate-text">
                      {{ researchData?.letter_code }}
                    </p>
                  </a-popover>
                </a-col>
                
                <a-col :span="5">
                  <p class="font-semibold text-md">มอบอำนาจเกี่ยวกับ</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="18">
                  <a-popover>
                    <p class="text-md w-full break-normal truncate-text">
                      {{ researchData?.power_topic }}
                    </p>
                  </a-popover>
                </a-col>
              </a-row>

              <a-row :gutter="[8, 8]">
                <a-col :span="24">
                  <p class="font-semibold text-md"><u>ข้อมูลส่วนเนื้อหาของบันทึกข้อความ</u></p>
                </a-col>
              </a-row>
              <a-row :gutter="[8, 8]" class="align-center">
                <a-col :span="5">
                  <p class="font-semibold text-md">ชื่อผู้รับมอบอำนาจ</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="18">
                  <p class="text-md w-full break-normal">
                    {{ researchData?.power_for }}
                  </p>
                </a-col>

                <a-col :span="5">
                  <p class="font-semibold text-md">ตำแหน่ง</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="18">
                  <p class="text-md w-full break-normal">
                    {{ researchData?.report_position_id }}
                  </p>
                </a-col>

                <a-col :span="5">
                  <p class="font-semibold text-md">เป็นผู้มีอำนาจในการ</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="18">
                  <a-popover>
                    <p class="text-md w-full break-normal truncate-text">
                      {{ researchData?.report_power_id }}
                    </p>
                  </a-popover>
                </a-col>
                
                <a-col :span="5">
                  <p class="font-semibold text-md">ภายใต้โครงการ</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="18">
                  <a-popover>
                    <p class="text-md w-full break-normal truncate-text">
                      {{ researchData?.power_project }}
                    </p>
                  </a-popover>
                </a-col>
              </a-row>

              

              <a-row :gutter="[8, 8]">
                <a-col :span="24">
                  <p class="font-semibold text-md"><u>ข้อมูลส่วนท้ายของหนังสือมอบอำนาจ</u></p>
                </a-col>
              </a-row>
              <a-row :gutter="[8, 8]" class="align-center">
                <a-col :span="5">
                  <p class="font-semibold text-md">ผู้ลงนามหนังสือมอบอำนาจ</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="18">
                  <a-popover>
                    <p class="text-md w-full break-normal truncate-text">
                      {{ researchData?.letter_sign_id }}
                    </p>
                  </a-popover>
                </a-col>
              </a-row>
              <a-row :gutter="[8, 8]" class="align-center">
                <a-col :span="5">
                  <p class="font-semibold text-md">ตำแหน่งผู้ลงนามหนังสือมอบอำนาจ</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="18">
                  <a-popover>
                    <p class="text-md w-full break-normal truncate-text">
                      {{ researchData?.letter_sign_position_id }}
                    </p>
                  </a-popover>
                </a-col>
              </a-row>
              <a-row :gutter="[8, 8]" class="align-center">
                <a-col :span="5">
                  <p class="font-semibold text-md">วิธีการลงนาม</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="5">
                  <a-popover>
                    <p class="text-md w-full break-normal truncate-text">
                      {{ researchData?.power_action_id }}
                    </p>
                  </a-popover>
                </a-col>
                <a-col :span="5">
                  <p class="font-semibold text-md">วันที่ลงนามในคำสั่ง (ถ้ามี)</p>
                </a-col>
                <a-col :span="1">
                  <p class="font-semibold text-md">:</p>
                </a-col>
                <a-col :span="5">
                  <a-popover>
                    <p class="text-md w-full break-normal truncate-text" v-if="researchData?.letter_sign_date">
                      {{ formatDate(researchData?.letter_sign_date) }}
                    </p>
                  </a-popover>
                </a-col>
              </a-row>

              
            </div>
          </div>
        </a-col>

        <a-col :lg="10" :md="24" class="w-full">
          <div
            class="w-full bg-white boxShadow-main"
            :style="{
              borderRadius: '10px',
              height: '100%',
              overflow: 'hidden',
            }"
          >
            <div class="flex items-center gap-4 p-4 box-header-color">
              <h1 class="text-lg">เอกสารแนบ</h1>
            </div>

            <!-- <a-divider
              class="my-3"
              :style="{ border: '1px solid rgba(243, 157, 190, 0.7) ' }"
            /> -->

            <div class="flex flex-col gap-4 justify-between p-4">
              <!-- <div>
                <p :style="{ fontSize: '14px', opacity: '0.6' }">
                  แนบได้เฉพาะไฟล์ขนาดไม่เกิน 8 MB
                </p>
              </div> -->

              <div>
                <a-row :gutter="[8, 8]">
                  <a-col :span="6" class="flex items-center">
                    <p class="font-semibold text-md">เอกสารแนบ 1</p>
                  </a-col>
                  <a-col :span="1" class="flex items-center">
                    <p class="font-semibold text-md">:</p>
                  </a-col>
                  <a-col :span="17">
                    <div v-if="researchData?.attach_1 != null">
                    <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${researchData?.attach_1}`"  target="_blank" style="display: ruby;">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                        <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                      </svg>
                      เอกสารแนบที่ 1
                    </a>
                    </div>
                    <div v-else-if="researchData?.attach_1 == null">
                      <p class="font-normal text-md">ไม่พบไฟล์</p>
                    </div>
                  </a-col>
                </a-row>
              </div>

              <div>
                <a-row :gutter="[8, 8]">
                  <a-col :span="6" class="flex items-center">
                    <p class="font-semibold text-md">เอกสารแนบ 2</p>
                  </a-col>
                  <a-col :span="1" class="flex items-center">
                    <p class="font-semibold text-md">:</p>
                  </a-col>
                  <a-col :span="17">
                    <div v-if="researchData?.attach_2 != null">
                    <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${researchData?.attach_2}`"  target="_blank" style="display: ruby;">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                        <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                      </svg>
                      เอกสารแนบที่ 1
                    </a>
                    </div>
                    <div v-else-if="researchData?.attach_2 == null">
                      <p class="font-normal text-md">ไม่พบไฟล์</p>
                    </div>
                  </a-col>
                </a-row>
              </div>

              <div>
                <a-row :gutter="[8, 8]">
                  <a-col :span="6" class="flex items-center">
                    <p class="font-semibold text-md">เอกสารแนบ 3</p>
                  </a-col>
                  <a-col :span="1" class="flex items-center">
                    <p class="font-semibold text-md">:</p>
                  </a-col>
                  <a-col :span="17">
                    <div v-if="researchData?.attach_3 != null">
                    <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${researchData?.attach_3}`"  target="_blank" style="display: ruby;">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                        <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                      </svg>
                      เอกสารแนบที่ 1
                    </a>
                    </div>
                    <div v-else-if="researchData?.attach_3 == null">
                      <p class="font-normal text-md">ไม่พบไฟล์</p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>

 
      <!-- Button Actions -->
      <div
        class="w-full bg-white p-4 boxShadow-main flex gap-4 items-center justify-end sticky bottom-0"
        :style="{ borderRadius: '10px', height: '100%' }"
      >
        <div :style="{ minWidth: '10rem' }">
          <ButtonUCVue label="ย้อนกลับ" :onClicked="backPage" />
        </div>
        <div
          :style="{ minWidth: '10rem' }"
          v-if="
            researchData?.approve_status == 'waiting' ||
            researchData?.approve_status == 'cancel '
          "
        >
          <ButtonUCVue
            label="บันทึกร่าง"
            :onClicked="() => submitFile('saveDraft')"
            :isDisabled="
              Boolean(mainFiles.files) ||
              Boolean(extendFiles.files) ||
              Boolean(billFiles.files)
                ? false
                : true
            "
          />
        </div>
        <div
          :style="{ minWidth: '10rem' }"
          v-if="$store.state.routeName.fullPath == '/research-list'"
        >
          <ButtonUCVue
            :label="
              researchData?.approve_status == 'approve' ? 'บันทึก' : 'นำส่ง'
            "
            :onClicked="() => submitFile()"
            :isBg="true"
            :isDisabled="
              Boolean(mainFiles.files) ||
              Boolean(extendFiles.files) ||
              Boolean(billFiles.files)
                ? false
                : true
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonUCVue from "../../components/ButtonUC.vue";
import ImageService from "../../services/ImageService";
import dayjs from "dayjs";
import moment from "moment";

import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

import { HTTP } from "../../http-common";

// icon
import BaseIcon from "../../components/Icon/BaseIcon.vue";
import DateFunction from "../../services/DateFunction";

export default {
  components: {
    ButtonUCVue,
    Swiper,
    SwiperSlide,
    BaseIcon,
  },

  mounted() {
    if (this.$router.currentRoute._rawValue.params.id) {
      this.id = this.$router.currentRoute._rawValue.params.id;
      this.getReportPublications(this.id);
      
    }
    
    
    this.$store.commit("setChild", this.$router.currentRoute._value);
  },

  unmounted() {
    this.$store.commit("setClearFileSave");
    this.$store.commit("setChild", "");
  },

  setup() {
    const swiperInstance = ref(null);
    const onSwiper = (swiper) => {
      // console.log(swiper);
    };
    const onSlideChange = () => {
      // console.log("slide change");
    };

    const swiperNext = () => {
      if (swiperInstance.value) {
        swiperInstance.value.slideNext();
      }
    };

    const swiperPrev = () => {
      if (swiperInstance.value) {
        swiperInstance.value.slidePrev();
      }
    };
    return {
      onSwiper,
      onSlideChange,
      swiperPrev,
      swiperNext,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  computed: {
    // Computed property to enable/disable button
    isButtonDisabled() {
      // return true;
      // Disable button if approver_comment is empty
      if(this.approver_comment === ''){
        return true;
      }else{
        return false;
      }
      // return this.approver_comment.trim() === '';
    },
    isiconColor() {
      // Disable button if approver_comment is empty
      if(this.approver_comment === ''){
        return '#FFF';
      }else{
        return '#ED4081';
      }
      // return this.approver_comment.trim() === '';
    },
  },
  methods: {
    handleInputChange(value) {
      this.approver_comment = value;
    },
    async getReportPublications(id) {
      let loader = this.$loading.show();
      try {

        const { data } = await HTTP.get(`/getReportPublicationsDetail/${id}`);

        if (data.data && data.data.length > 0) {
            this.researchData = data?.data[0]; /* Fetch to Values */
            this.sort_step = data?.data[0]?.sort_step;
            this.letter_sign_id = data?.data[0]?.sign_id;
            this.letter_sign_position_id = data?.data[0]?.sign_position_id;
            this.report_position_id = data?.data[0]?.report_position_id;
            this.report_power_id = data?.data[0]?.report_power_id;
            this.power_action_id = data?.data[0]?.power_action_id;


            this.researchData = data?.data[0];
            this.latestStatus= data?.data[0].latestStatus;
            this.action_step= data?.data[0].action_step;
            this.nextStatus= data?.data[0].nextStatus;
            this.approves_by= data?.data[0].approves_by;
            this.latestStepIndex= data?.data[0].latestStepIndex;
            this.foundSteps= data?.data[0].foundSteps;


            if (data.data[0].letter_sign_date) {
              let return_letter_sign_date = data.data[0].letter_sign_date;
              let convert_letter_sign_date = dayjs(return_letter_sign_date, 'YYYY-MM-DD').locale("th");
              this.letter_sign_date = convert_letter_sign_date;
            } else {
              this.letter_sign_date = "";
            }

            this.ReportData = data?.data[0];
            loader.hide();
        } else {
            this.ReportData = null;
            loader.hide();
        }

        
      } catch (error) {
        console.error(error.message);
      } finally {
        loader.hide();
      }
    },
    
    async uploadOtherFile(file) {
      let loader = this.$loading.show();
      const file_name = file.name;
      const getFile = await ImageService.getBase64(file);

      if (getFile) {
        const { data } = await HTTP.post("/uploadotherfile", {
          id: this.id,
          file: getFile,
          file_name: file_name,
          approve_type: this.researchData?.approve_type,
          approve_index: this.researchData?.approve_index,
        });

        if (data.success) {
          loader.hide();
          this.$swal.fire({
            title: "แนบไฟล์สำเร็จ",
            width: 300,
            height: 300,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(() => {
            this.getReportResearchs(this.id);
          }, 3000);
        }
      }
    },

    async onChangedFile(file, name) {
      const fileBase64 = await ImageService.getBase64(file);
      switch (name) {
        case "main":
          this.new_main_file = true;

          this.mainFiles = {
            name: file.name,
            files: fileBase64,
          };

          this.$store.commit("setFileSave", { ...this.mainFiles, fileNo: 1 });
          return;
        case "bill":
          this.new_bill_file = true;

          this.billFiles = {
            name: file.name,
            files: fileBase64,
          };

          this.$store.commit("setFileSave", { ...this.billFiles, fileNo: 2 });
          return;
        case "extend":
          this.new_extend_file = true;

          this.extendFiles = {
            name: file.name,
            files: fileBase64,
          };

          this.$store.commit("setFileSave", { ...this.extendFiles, fileNo: 3 });
          return;
      }
    },

    async saveFile(stauts) {
      const fileNow = this.research_approve?.find((item) => {
        return item.approve_index == this.indexNow;
      });

      let body = {
        id: this.id,
        approve_type: fileNow?.approve_type,
        // approve_index: this.researchData?.approve_index,
        approve_index: this.indexNow,
        approve_status: stauts,
      };

      if (this.mainFiles.files != null && this.new_main_file) {
        body = {
          ...body,
          file_1: this.mainFiles.files,
          file_name_1: this.mainFiles.name,
          file_type_1: "funding",
        };
      }

      if (this.billFiles.files != null && this.new_bill_file) {
        body = {
          ...body,
          file_2: this.billFiles.files,
          file_name_2: this.billFiles.name,
          file_type_2: "bill",
        };
      }

      if (this.extendFiles.files != null && this.new_extend_file) {
        body = {
          ...body,
          file_3: this.extendFiles.files,
          file_name_3: this.extendFiles.name,
          file_type_3: "contract",
        };
      }

      console.log(body);

      const { data } = await HTTP.post("/uploadfiles", body);

      return data.success;
    },

    async submitFile(type) {
      if (
        this.mainFiles.files != null ||
        this.billFiles.files != null ||
        this.extendFiles.files != null
      ) {
        let loader = this.$loading.show();

        if (type == "saveDraft") {
          const response = this.saveFile(this.researchData?.approve_status);
          if (response) {
            loader.hide();
            this.$swal.fire({
              title: "บันทึกสำเร็จ",
              width: 300,
              height: 300,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
            });
            setTimeout(() => {
              this.new_main_file = false;
              this.new_bill_file = false;
              this.new_extend_file = false;
              this.getReportResearchs(this.id);
            }, 3000);
          }
        } else {
          let projectStatus = "pending";
          let fileStatus = "pending";

          const fileNow = this.research_approve?.find((item) => {
            return item.approve_index == this.indexNow;
          });

          if (fileNow?.approve_status == "approve")
            (projectStatus = "approve"), (fileStatus = "null");

          const response = this.saveFile(fileStatus);
          if (response) {
            let body;
            if (this.researchData?.approve_index == this.indexNow) {
              body = {
                id: this.id,
                approve_index: this.researchData?.approve_index,
                approve_type: this.researchData?.approve_type,
                approve_status: projectStatus,
                file_status: fileStatus,
              };
            } else {
              body = {
                id: this.id,
                approve_index: this.researchData?.approve_index,
                approve_type: this.researchData?.approve_type,
                approve_status: this.researchData?.approve_status,
                file_status: this.researchData?.approve_status,
              };
            }

            const { data } = await HTTP.post("/sendApproveProject", body);

            if (data.status) {
              loader.hide();
              this.$swal.fire({
                title: "บันทึกสำเร็จ",
                width: 300,
                height: 300,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
              });

              this.$store.commit("setClearFileSave");

              setTimeout(() => {
                this.new_main_file = false;
                this.new_bill_file = false;
                this.new_extend_file = false;
                this.getReportResearchs(this.id);
              }, 3000);
            }
          }
        }
      } else {
        this.$swal.fire({
          icon: "error",
          width: 300,
          title: "กรุณาแนบไฟล์",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    async submitDueDate() {
      if (this.stepDueDate) {
        const body = {
          id: this.id,
          approve_type: this.researchData?.approve_type,
          approve_index: this.researchData?.approve_index,
          project_due_date: moment(new Date(this.stepDueDate))
            .format("YYYY-MM-DD"),
        }

        const { data } = await HTTP.post("/updateProjectEndDate", body);

        this.new_step_end_date = false;
        this.$swal.fire({
          title: "บันทึกสำเร็จ",
          width: 300,
          height: 300,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    showDeletePopup(record) {
      this.$swal
        .fire({
          iconHtml:
            '<svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none"> <path d="M47.1667 11.667C47.7855 11.667 48.379 11.9128 48.8166 12.3504C49.2542 12.788 49.5 13.3815 49.5 14.0003C49.5 14.6192 49.2542 15.2127 48.8166 15.6502C48.379 16.0878 47.7855 16.3337 47.1667 16.3337H44.8333L44.8263 16.4993L42.6493 46.9983C42.5655 48.1757 42.0387 49.2776 41.1749 50.082C40.3112 50.8864 39.1747 51.3337 37.9943 51.3337H19.0033C17.823 51.3337 16.6865 50.8864 15.8227 50.082C14.959 49.2776 14.4321 48.1757 14.3483 46.9983L12.1713 16.5017C12.1678 16.4457 12.1662 16.3897 12.1667 16.3337H9.83333C9.21449 16.3337 8.621 16.0878 8.18342 15.6502C7.74583 15.2127 7.5 14.6192 7.5 14.0003C7.5 13.3815 7.74583 12.788 8.18342 12.3504C8.621 11.9128 9.21449 11.667 9.83333 11.667H47.1667ZM33.1667 4.66699C33.7855 4.66699 34.379 4.91282 34.8166 5.35041C35.2542 5.78799 35.5 6.38149 35.5 7.00033C35.5 7.61916 35.2542 8.21266 34.8166 8.65024C34.379 9.08783 33.7855 9.33366 33.1667 9.33366H23.8333C23.2145 9.33366 22.621 9.08783 22.1834 8.65024C21.7458 8.21266 21.5 7.61916 21.5 7.00033C21.5 6.38149 21.7458 5.78799 22.1834 5.35041C22.621 4.91282 23.2145 4.66699 23.8333 4.66699H33.1667Z" fill="#ED4040"/> </svg>',
          title: "ยืนยันการลบ",
          text: "ต้องการลบหรือไม่",
          showCancelButton: true,
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "ยืนยัน",
          confirmButtonColor: "#ED4040",
          cancelButtonColor: "#FDECEC",
          customClass: {
            cancelButton: "cancelButtonOnReject",
            popup: "borderReject",
            icon: "iconBorder-0",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteOtherFiles(record.id);
          }
        });
    },

    async deleteOtherFiles(id) {
      const { data } = await HTTP.post("/deleteotherfile", {
        id: this.id,
        id: id.toString(),
      });
      if (data.success) {
        this.getReportResearchs(this.id);
      }
    },

    async nextStep(type, idx) {
      let loader = this.$loading.show();
      const { data } = await HTTP.post("/approveNextstep", {
        id: this.id,
        approve_type: type,
        approve_index: idx,
      });

      if (data.success) {
        loader.hide();
        this.stepDueDate = "";
        this.getReportResearchs(this.id);
      }
    },

    async stepperClicked(value) {
   
      try {

        const { data } = await HTTP.get(`/getReportComment/${value}`);
        if (data.data && data.data.length > 0) {
          console.log(data?.data[0]);
          this.approver_comment_value = "ข้อความ/หมายเหตุ : "+data?.data[0]?.approver_comment;
        }else{
           this.approver_comment_value = "";
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        loader.hide();
      }
  

      // this.indexNow = step;
      // this.setFileTitle();
      // let clearFile = {
      //   name: null,
      //   files: null,
      // };

      // // set History File
      // this.researchHistoryFile = this.researchData?.files
      //   ?.filter((item) => item.approve_index == this.indexNow)
      //   .reverse();

      // // setOtherFile
      // this.researchOtherFile = this.researchData?.other_files
      //   ?.filter((item) => item.approve_index == this.indexNow)
      //   .map((item) => {
      //     const fileType = item?.file_path
      //       .split(";")[0]
      //       .split("/")[1]
      //       .toUpperCase();
      //     const fileSize = this.getFileSizeFromBase64(item?.file_path);
      //     return {
      //       ...item,
      //       file_type: fileType,
      //       file_size: fileSize,
      //     };
      //   })
      //   .reverse();

      // // set main file
      // let allFileMain = this.researchData?.files?.filter(
      //   (item) => item.is_used == 1 && item.approve_index == this.indexNow
      // );

      // let date =
      //   this.research_approve.find(
      //     (item) => item.approve_index == this.indexNow
      //   ).project_due_date ?? "";
      // this.stepDueDate = Boolean(date) ? dayjs(date) : "";

      // if (allFileMain && allFileMain.length > 0) {
      //   const mainFile = allFileMain.find(
      //     (item) => item.file_type == "funding"
      //   );
      //   const billFile = allFileMain.find((item) => item.file_type == "bill");
      //   const contractFile = allFileMain.find(
      //     (item) => item.file_type == "contract"
      //   );

      //   if (mainFile) {
      //     this.mainFiles = {
      //       name: mainFile.file_name,
      //       files: mainFile.file_path,
      //     };
      //   } else {
      //     this.mainFiles = clearFile;
      //   }

      //   if (billFile) {
      //     this.billFiles = {
      //       name: billFile.file_name,
      //       files: billFile.file_path,
      //     };
      //   } else {
      //     this.billFiles = clearFile;
      //   }

      //   if (contractFile) {
      //     this.extendFiles = {
      //       name: contractFile.file_name,
      //       files: contractFile.file_path,
      //     };
      //   } else {
      //     this.extendFiles = clearFile;
      //   }
      // } else {
      //   this.mainFiles = clearFile;
      //   this.billFiles = clearFile;
      //   this.extendFiles = clearFile;
      // }

      // this.new_bill_file = false;
      // this.new_main_file = false;
      // this.new_extend_file = false;
      // this.new_step_end_date = false;
    },

    onDowloadPDFFile(file) {
      const downloadLink = document.createElement("a");
      downloadLink.href = Boolean(file.files) ? file.files : file.file_path;
      downloadLink.download = Boolean(file.name) ? file.name : file.file_name;
      downloadLink.click();
    },

    backPage() {
      if (this.new_main_file || this.new_bill_file || this.new_extend_file) {
        this.$swal
          .fire({
            title: "ยืนยันการละทิ้ง",
            text: "ต้องการละทิ้งหรือไม่ ข้อมูลจะไม่ถูกบันทึก",
            icon: "warning",
            iconColor: "#30a702",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            confirmButtonColor: "#ED4040",
            cancelButtonColor: "#FDECEC",
            customClass: {
              cancelButton: "cancelButtonOnReject",
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              let path = this.$store.state.routeName.href;
              this.$router.push(path);
            }
          });
      } else {
        let path = this.$store.state.routeName.href;
        this.$router.push(path);
      }
    },

    formatDate(date) {
      return DateFunction.formatDate(date);
    },

    otherFileNextPage(page) {
      this.paginationOtherFileConfig = {
        ...this.paginationOtherFileConfig,
        current: page,
      };
    },

    historyFileNextPage(page) {
      this.paginationHistoryFileConfig = {
        ...this.paginationHistoryFileConfig,
        current: page,
      };
    },

    onClickApprove(type) {
      this.$swal
        .fire({
          title: type == "approve" ? "ยืนยันการอนุมัติ" : "ยืนยันการแก้ไข/ตีกลับ",
          icon: type == "approve" ? "success" : "error",
          showCancelButton: true,
          confirmButtonColor: type == "approve" ? "#84CF75" : "#ED4040",
          cancelButtonColor: type == "approve" ? "#F1FAEF" : "#FDECEC",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          customClass: {
            cancelButton:
              type == "approve"
                ? "cancelButtonOnApprove"
                : "cancelButtonOnReject",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.ApproveResearchs(type);
          }
        });
    },

    async ApproveResearchs(type) {
      let loader = this.$loading.show();
      let body = {
        id: this.researchData?.approve_id,
        approve_status: type,
        report_id: this.researchData?.report_id,
        report_table: this.researchData?.report_table,
        approve_step: this.researchData?.approve_step_step,
        approver_comment: this.approver_comment,
      };
      console.log(this.researchData?.approve_step);

      const { data } = await HTTP.post("/ApproveResearchs", body);

      if (data.success) {
        loader.hide();
        this.$swal.fire({
          title: "ทำรายการสำเร็จ",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
          width: 300,
        });

        setTimeout(() => {
          this.getReportResearchs(this.id);
        }, 2000);
      }
    },

    setFileTitle() {
      switch (this.indexNow) {
        case 0:
          this.main_title = "ไฟล์สัญญาทุน";
          this.bill_title = "แนบบิลงวดที่ 1";
          this.extend_title = "ขยายระยะเวลาสัญญา";
          break;
        case 1:
          this.main_title = "ไฟล์รายงาน";
          this.bill_title = "แนบบิลงวดที่ 2";
          this.extend_title = "ขยายระยะเวลาสัญญา";
          break;
        case 2:
          this.main_title = "ไฟล์รายงาน";
          this.bill_title = "แนบบิลงวดที่ 3";
          this.extend_title = "ขยายระยะเวลาสัญญา";
          break;
        case 3:
          this.main_title = "ไฟล์รายงาน";
          this.bill_title = "แนบบิลงวดที่ 4";
          this.extend_title = "ขยายระยะเวลาสัญญา";
          break;
        case 4:
          this.main_title = "ไฟล์รายงาน";
          this.bill_title = "แนบบิลงวดที่ 5";
          this.extend_title = "ขยายระยะเวลาสัญญา";
          break;
        case 5:
          this.main_title = "ไฟล์รายงาน";
          this.bill_title = "แนบบิลงวดที่ 6";
          this.extend_title = "ขยายระยะเวลาสัญญา";
          break;
        case 6:
          this.main_title = "แนบไฟล์รายงานฉบับสมบูรณ์";
          this.bill_title = "แนบเงินประกันผล";
          this.extend_title = "แนบเงินประกันงวดสุดท้าย";
          break;
        case 7:
          this.main_title = "แนบไฟล์รายงานฉบับแก้ไข";
          this.bill_title = "แนบเงินประกันผล";
          this.extend_title = "";
          break;
        case 8:
          this.main_title = "แนบไฟล์นำส่ง";
          this.bill_title = "OHC ECON";
          this.extend_title = "OHC CMU";
          break;
      }
    },

    getStepperClass(item,step) {

      if (item == 0 && step == this.nextStatus) {
        return "current";
      } else if (item == 1) {
        return "complete";
      } else {
        return "";
      }
    },

    getStepperTitle(item,step) {
      if (item == 0 && step == this.nextStatus) {
        return "progressIcon";
      } else if (item == 1) {
        return "fundingIcon";
      } else {
        return "lockIcon";
      }
      // switch (item) {
      //   case "0":
      //     return "funding";
      //   case "1":
      //     return `progress`;
      //   case "complete":
      //     return "complete";
      //   case "edit":
      //     return "edit";
      // }
    },

    getStepperIconColor(item,step) {

      if (item == 0 && step == this.nextStatus) {
        return "white";
      } else if (item == 1) {
        return "#ED4081";
      } else {
        return "";
      }
    },

    otherFileSizeChange(current, size) {
      this.paginationOtherFileConfig = {
        ...this.paginationOtherFileConfig,
        current: current,
        pageSize: size,
      };
    },

    historyFileSizeChange(current, size) {
      this.paginationHistoryFileConfig = {
        ...this.paginationHistoryFileConfig,
        current: current,
        pageSize: size,
      };
    },

    checkStatus() {
      return (
        this.research_approve?.find(
          (item) => item.approve_index == this.indexNow
        )?.approve_status == "pending"
      );
    },

    getFileSizeFromBase64(base64String) {
      if (base64String) {
        const base64WithoutPrefix = base64String.replace(
          /^data:[^;]+;base64,/,
          ""
        );
        const binaryData = atob(base64WithoutPrefix);
        const fileSize = binaryData.length;

        const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);

        return fileSizeInMB + " MB";
      }
    },

    getSwiperSide() {
      if (
        this.researchData?.approve_index == 6 &&
        this.researchData?.project_approve?.length == 2
      ) {
        return 3;
      } else if (
        this.researchData?.approve_index == 8 &&
        this.researchData?.project_approve?.length == 3
      ) {
        return 3;
      }
      return 4;
    },
  },

  data() {
    return {
      loader: "",
      approver_comment: "",
      approver_comment_value: "",
      id: "",
      indexNow: 0,
      researchData: null,
      researchOtherFile: [],
      letter_sign_id: [],
      letter_sign_position_id: [],
      letter_project_head: [],
      research_approve: null,
      status_progress: null,
      stepDueDate: "",

      progress_step: null,
      complete_step: null,
      ohc_step: null,
        
      
      otherFileColumns: [
        {
          title: "ลำดับ",
          dataIndex: "rowNumber",
          width: 5,
          align: "center",
        },
        {
          title: "ชื่อไฟล์",
          dataIndex: "file_name",
          width: 350,
        },
        {
          title: "ชนิดไฟล์",
          dataIndex: "file_type",
          width: 50,
          align: "center",
        },
        {
          title: "ขนาดไฟล์",
          dataIndex: "file_size",
          width: 50,
          align: "center",
        },
        {
          title: "วันที่แนบไฟล์",
          dataIndex: "created_date",
          width: 50,
          align: "center",
        },
        {
          dataIndex: "otherFile-oparetion",
          width: 5,
          align: "center",
        },
      ],

      researchHistoryFile: [],
      historyFileColumns: [
        {
          title: "ลำดับ",
          dataIndex: "rowNumber",
          width: 5,
          align: "center",
        },
        {
          title: "ชื่อไฟล์",
          dataIndex: "file_name",
          width: 350,
        },
        {
          title: "วันที่แนบไฟล์",
          dataIndex: "created_date",
          width: 50,
          align: "center",
        },
        {
          title: "ผลการดำเนินงาน",
          dataIndex: "approve_status",
          align: "center",
          width: 30,
        },
        {
          title: "วันที่อัปเดตผลการดำเนินงาน",
          dataIndex: "updated_date",
          align: "center",
          width: 120,
        },
      ],

      paginationOtherFileConfig: {
        total: 0,
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 50, 100],
        onChange: this.otherFileNextPage,
        onShowSizeChange: this.otherFileSizeChange,
      },

      paginationHistoryFileConfig: {
        total: 0,
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 50, 100],
        onChange: this.historyFileNextPage,
        onShowSizeChange: this.historyFileSizeChange,
      },

      mainFiles: {
        name: "",
        files: null,
      },
      billFiles: {
        name: "",
        files: null,
      },
      extendFiles: {
        name: "",
        files: null,
      },
      new_main_file: false,
      new_bill_file: false,
      new_extend_file: false,
      new_step_end_date: false,
      main_title: "ไฟล์สัญญาทุน",
      bill_title: "แนบบิลงวดที่ 1",
      extend_title: "ขยายระยะเวลาสัญญา",

      filterTable: [
        { label: "เรียงตามโครงการวิจัยที่อัปเดตใหม่ที่สุด", value: 0 },
        { label: "เรียงตามโครงการวิจัยที่อัปเดตเก่าที่สุด", value: 1 },
        { label: "เรียงตามโครงการวิจัยที่สร้างใหม่ที่สุด", value: 2 },
        { label: "เรียงตามโครงการวิจัยที่สร้างเก่าที่สุด", value: 3 },
      ],
    };
  },
};
</script>

<style scoped>
.mySwiper {
  display: flex;
  width: 100%;
  margin-left: 55px;
  margin-right: 65px;
}

.swiper-button-prev,
.swiper-button-next {
  width: 44px;
  background: #f06599;
  border-radius: 50%;
  z-index: 2;
  top: var(--swiper-navigation-top-offset, 37%);
}

/* .swiper-button-disabled {
  opacity: 1 !important;
} */

.swiper-button-lock {
  display: block !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 24px;
  color: #fff;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 0px);
  right: auto;
}

.stepper__item {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
