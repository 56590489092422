// layouts

import Admin from "@/layouts/Admin.vue";
// import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import ResearchList from "@/views/research/ResearchList.vue";
import ResearchCheck from "@/views/research/ResearchCheck.vue";
// import Tables from "@/views/admin/Tables.vue";
import ResearchDetail from "@/views/research/ResearchDetail.vue";

// views for Auth layout

// import Login from "@/views/auth/Login.vue";
// import Register from "@/views/auth/Register.vue";

// views without layouts

// import Landing from "@/views/Landing.vue";
// import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";

import ReportSignList from "@/views/setting/ReportSignList.vue";
import ReportSignAdd from "@/views/setting/ReportSignAdd.vue";
import ReportSignEdit from "@/views/setting/ReportSignEdit.vue";
import ReportSignPositionList from "@/views/setting/ReportSignPositionList.vue";
import ReportSignPositionAdd from "@/views/setting/ReportSignPositionAdd.vue";
import ReportSignPositionEdit from "@/views/setting/ReportSignPositionEdit.vue";
import ReportApprovers from "@/views/setting/ReportApprovers.vue";
import ReportApproversEdit from "@/views/setting/ReportApproversEdit.vue";

import ReportResearchsList from "@/views/report/ReportResearchsList.vue";
import ReportResearchsAdd from "@/views/report/ReportResearchsAdd.vue";
import ReportResearchsEdit from "@/views/report/ReportResearchsEdit.vue";

import ReportPublicationsList from "@/views/report/ReportPublicationsList.vue";
import ReportPublicationsAdd from "@/views/report/ReportPublicationsAdd.vue";
import ReportPublicationsEdit from "@/views/report/ReportPublicationsEdit.vue";

import ReportAuthorizesList from "@/views/report/ReportAuthorizesList.vue";
import ReportAuthorizesAdd from "@/views/report/ReportAuthorizesAdd.vue";
import ReportAuthorizesEdit from "@/views/report/ReportAuthorizesEdit.vue";

import ReportPublishedList from "@/views/report/ReportPublishedList.vue";
import ReportPublishedAdd from "@/views/report/ReportPublishedAdd.vue";
import ReportPublishedEdit from "@/views/report/ReportPublishedEdit.vue";


import ReportResearchsApprove from "@/views/approve/ReportResearchsApprove.vue";
import ResearchsApprove from "@/views/approve/ResearchsApprove.vue";

import ReportPublicationsApprove from "@/views/approve/ReportPublicationsApprove.vue";
import PublicationsApprove from "@/views/approve/PublicationsApprove.vue";

import ReportAuthorizesApprove from "@/views/approve/ReportAuthorizesApprove.vue";
import AuthorizesApprove from "@/views/approve/AuthorizesApprove.vue";

import ReportPublishedApprove from "@/views/approve/ReportPublishedApprove.vue";
import PublishedApprove from "@/views/approve/PublishedApprove.vue";


import ApproveWait from "@/views/approve/ApproveWait.vue";
import ApproveReject from "@/views/approve/ApproveReject.vue";
import ApproveDone from "@/views/approve/ApproveDone.vue";

import ResearchsDetail from "@/views/approve/ResearchsDetail.vue";
import PublicationsDetail from "@/views/approve/PublicationsDetail.vue";
import AuthorizesDetail from "@/views/approve/AuthorizesDetail.vue";
import PublishedDetail from "@/views/approve/PublishedDetail.vue";
// routes

import EncryptServices from "../services/EncryptServices";

const commonGuard = (to, from, next) => {
  const encryptedUser = localStorage.getItem("user");
  const user = encryptedUser
    ? JSON.parse(EncryptServices.decrypt(encryptedUser))
    : null;
  if (user) {
    if (user.role == "research" && to.path == "/research-check") {
      next("/research-list");
    }
    next();
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/login",
    component: Index,
    children: [
      {
        path: "/login",
        component: Index,
      },
    ],
  },
  {
    path: "/",
    redirect: "/research-list",
    component: Admin,
    children: [
      {
        path: "/research-list",
        name: "รายการโครงการวิจัย",
        component: ResearchList,
        beforeEnter: commonGuard,
      },
      {
        path: "/research-check",
        name: "ตรวจสอบโครงการวิจัย",
        component: ResearchCheck,
        beforeEnter: commonGuard,
      },
      {
        path: "/research-details/:project_id",
        name: "รายละเอียดโครงการวิจัย",
        component: ResearchDetail,
        beforeEnter: (to, from, next) => {
          if (from.path == "/research-list" || from.path == "/research-check") {
            return next();
          }
          next("/research-list");
        },
      },
    ],
  },
 
  {
    path: "/",
    redirect: "/setting-sign",
    component: Admin,
    children: [
        {
          path: "/setting-sign",
          name: "ตั้งค่าผู้ลงนาม",
          component: ReportSignList,
          beforeEnter: commonGuard,
        },
        {
          path: "/setting-sign-add",
          name: "เพิ่มผู้ลงนาม",
          component: ReportSignAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-sign") {
              return next();
            }
            next("/setting-sign");
          },
        },
        {
          path: "/setting-sign-edit/:id",
          name: "แก้ไขผู้ลงนาม",
          component: ReportSignEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-sign") {
              return next();
            }
            next("/setting-sign");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/setting-sign-position",
    component: Admin,
    children: [
        {
          path: "/setting-sign-position",
          name: "ตั้งค่าตำแหน่ง",
          component: ReportSignPositionList,
          beforeEnter: commonGuard,
        },
        {
          path: "/setting-sign-position-add",
          name: "เพิ่มตำแหน่ง",
          component: ReportSignPositionAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-sign-position") {
              return next();
            }
            next("/setting-sign-position");
          },
        },
        {
          path: "/setting-sign-position-edit/:id",
          name: "แก้ไขตำแหน่ง",
          component: ReportSignPositionEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-sign-position") {
              return next();
            }
            next("/setting-sign-position");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/setting-approvers",
    component: Admin,
    children: [
        {
          path: "/setting-approvers",
          name: "ตั้งค่าลำดับการอนุมัติ",
          component: ReportApprovers,
          beforeEnter: commonGuard,
        },
        {
          path: "/setting-approvers-edit/:id",
          name: "แก้ไขลำดับการอนุมัติ",
          component: ReportApproversEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-approvers") {
              return next();
            }
            next("/setting-approvers");
          },
        },
        
    ],
  },

  {
    path: "/",
    redirect: "/report-researchs",
    component: Admin,
    children: [
        {
          path: "/report-researchs",
          name: "ขอเบิกเงินโครงการวิจัย",
          component: ReportResearchsList,
          beforeEnter: commonGuard,
        },
        {
          path: "/report-researchs-add",
          name: "รายงานขอเบิกเงินโครงการวิจัย",
          component: ReportResearchsAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-researchs") {
              return next();
            }
            next("/report-researchs");
          },
        },
        {
          path: "/report-researchs-edit/:id",
          name: "แก้ไขรายงานขอเบิกเงินโครงการวิจัย",
          component: ReportResearchsEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-researchs") {
              return next();
            }
            next("/report-researchs");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/report-publications",
    component: Admin,
    children: [
        {
          path: "/report-publications",
          name: "หนังสือมอบอำนาจ",
          component: ReportPublicationsList,
          beforeEnter: commonGuard,
        },
        {
          path: "/report-publications-add",
          name: "รายงานหนังสือมอบอำนาจ",
          component: ReportPublicationsAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-publications") {
              return next();
            }
            next("/report-publications");
          },
        },
        {
          path: "/report-publications-edit/:id",
          name: "แก้ไขรายงานหนังสือมอบอำนาจ",
          component: ReportPublicationsEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-publications") {
              return next();
            }
            next("/report-publications");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/report-authorizes",
    component: Admin,
    children: [
        {
          path: "/report-authorizes",
          name: "หนังสือเชิญเข้าร่วม/ประชุม",
          component: ReportAuthorizesList,
          beforeEnter: commonGuard,
        },
        {
          path: "/report-authorizes-add",
          name: "รายงานหนังสือเชิญเข้าร่วม/ประชุม",
          component: ReportAuthorizesAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-authorizes") {
              return next();
            }
            next("/report-authorizes");
          },
        },
        {
          path: "/report-authorizes-edit/:id",
          name: "แก้ไขรายงานหนังสือเชิญเข้าร่วม/ประชุม",
          component: ReportAuthorizesEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-authorizes") {
              return next();
            }
            next("/report-authorizes");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/report-published",
    component: Admin,
    children: [
        {
          path: "/report-published",
          name: "ขอรับค่าตีพิมพ์ผลงาน",
          component: ReportPublishedList,
          beforeEnter: commonGuard,
        },
        {
          path: "/report-published-add",
          name: "รายงานขอรับค่าตีพิมพ์ผลงาน",
          component: ReportPublishedAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-published") {
              return next();
            }
            next("/report-published");
          },
        },
        {
          path: "/report-published-edit/:id",
          name: "แก้ไขขอรับค่าตีพิมพ์ผลงาน",
          component: ReportPublishedEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-published") {
              return next();
            }
            next("/report-published");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/report-researchs-approve",
    component: Admin,
    children: [
        {
          path: "/report-researchs-approve",
          name: "พิจารณา/อนุมัติขอเบิกเงินโครงการวิจัย",
          component: ReportResearchsApprove,
          beforeEnter: commonGuard,
        },
        {
          path: "/research-details-approve/:id",
          name: "รายละเอียด พิจารณา/อนุมัติขอเบิกเงินโครงการวิจัย",
          component: ResearchsApprove,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-researchs-approve") {
              return next();
            }
            next("/report-researchs-approve");
          },
        },
        
      
    ],
  },
  {
    path: "/",
    redirect: "/report-publications-approve",
    component: Admin,
    children: [
        {
          path: "/report-publications-approve",
          name: "พิจารณา/อนุมัติหนังสือมอบอำนาจ",
          component: ReportPublicationsApprove,
          beforeEnter: commonGuard,
        },
        {
          path: "/publications-details-approve/:id",
          name: "รายละเอียด พิจารณา/อนุมัติหนังสือมอบอำนาจ",
          component: PublicationsApprove,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-publications-approve") {
              return next();
            }
            next("/report-publications-approve");
          },
        },
        
      
    ],
  },
  {
    path: "/",
    redirect: "/report-authorizes-approve",
    component: Admin,
    children: [
        {
          path: "/report-authorizes-approve",
          name: "พิจารณา/อนุมัติหนังสือเชิญเข้าร่วม/ประชุม",
          component: ReportAuthorizesApprove,
          beforeEnter: commonGuard,
        },
        {
          path: "/authorizes-details-approve/:id",
          name: "รายละเอียด พิจารณา/อนุมัติหนังสือเชิญเข้าร่วม/ประชุม",
          component: AuthorizesApprove,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-authorizes-approve") {
              return next();
            }
            next("/report-authorizes-approve");
          },
        },
        
      
    ],
  },
  {
    path: "/",
    redirect: "/report-published-approve",
    component: Admin,
    children: [
        {
          path: "/report-published-approve",
          name: "พิจารณา/อนุมัติรับค่าตีพิมพ์ผลงาน",
          component: ReportPublishedApprove,
          beforeEnter: commonGuard,
        },
        {
          path: "/published-details-approve/:id",
          name: "รายละเอียด พิจารณา/อนุมัติรับค่าตีพิมพ์ผลงาน",
          component: PublishedApprove,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-published-approve") {
              return next();
            }
            next("/report-published-approve");
          },
        },
        
      
    ],
  },
  
  {
    path: "/",
    redirect: "/approve-wait",
    component: Admin,
    children: [
        {
          path: "/approve-wait",
          name: "รอการพิจารณา/อนุมัติ",
          component: ApproveWait,
          beforeEnter: commonGuard,
        },
        {
          path: "/research-details-wait/:id",
          name: "รายละเอียดขอเบิกเงินโครงการวิจัย (รอการพิจารณา/อนุมัติ)",
          component: ResearchsDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-wait") {
              return next();
            }
            next("/approve-wait");
          },
        },
        {
          path: "/publications-details-wait/:id",
          name: "รายละเอียดหนังสือมอบอำนาจ (รอการพิจารณา/อนุมัติ)",
          component: PublicationsDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-wait") {
              return next();
            }
            next("/approve-wait");
          },
        },
        {
          path: "/authorizes-details-wait/:id",
          name: "รายละเอียดหนังสือเชิญเข้าร่วม/ประชุม (รอการพิจารณา/อนุมัติ)",
          component: AuthorizesDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-wait") {
              return next();
            }
            next("/approve-wait");
          },
        },
        {
          path: "/published-details-wait/:id",
          name: "รายละเอียดรับค่าตีพิมพ์ผลงาน (รอการพิจารณา/อนุมัติ)",
          component: PublishedDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-wait") {
              return next();
            }
            next("/approve-wait");
          },
        },
        
      
    ],
  },
  {
    path: "/",
    redirect: "/approve-reject",
    component: Admin,
    children: [
        {
          path: "/approve-reject",
          name: "ถูกตีกลับ-ให้แก้ไข",
          component: ApproveReject,
          beforeEnter: commonGuard,
        },
        {
          path: "/research-details-reject/:id",
          name: "รายละเอียดขอเบิกเงินโครงการวิจัย (ถูกตีกลับ-ให้แก้ไข)",
          component: ResearchsDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-reject") {
              return next();
            }
            next("/approve-reject");
          },
        },
        {
          path: "/publications-details-reject/:id",
          name: "รายละเอียดหนังสือมอบอำนาจ (ถูกตีกลับ-ให้แก้ไข)",
          component: PublicationsDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-reject") {
              return next();
            }
            next("/approve-reject");
          },
        },
        {
          path: "/authorizes-details-reject/:id",
          name: "รายละเอียดหนังสือเชิญเข้าร่วม/ประชุม (ถูกตีกลับ-ให้แก้ไข)",
          component: AuthorizesDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-reject") {
              return next();
            }
            next("/approve-reject");
          },
        },
        {
          path: "/published-details-reject/:id",
          name: "รายละเอียดรับค่าตีพิมพ์ผลงาน (ถูกตีกลับ-ให้แก้ไข)",
          component: PublishedDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-reject") {
              return next();
            }
            next("/approve-reject");
          },
        },
        
      
    ],
  },
  {
    path: "/",
    redirect: "/approve-done",
    component: Admin,
    children: [
        {
          path: "/approve-done",
          name: "เสร็จสิ้น/อนุมัติ",
          component: ApproveDone,
          beforeEnter: commonGuard,
        },
        {
          path: "/research-details-done/:id",
          name: "รายละเอียดขอเบิกเงินโครงการวิจัย (เสร็จสิ้น/อนุมัติ)",
          component: ResearchsDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-done") {
              return next();
            }
            next("/approve-done");
          },
        },
        {
          path: "/publications-details-done/:id",
          name: "รายละเอียดหนังสือมอบอำนาจ (เสร็จสิ้น/อนุมัติ)",
          component: PublicationsDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-done") {
              return next();
            }
            next("/approve-done");
          },
        },
        {
          path: "/authorizes-details-done/:id",
          name: "รายละเอียดหนังสือเชิญเข้าร่วม/ประชุม (เสร็จสิ้น/อนุมัติ)",
          component: AuthorizesDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-done") {
              return next();
            }
            next("/approve-done");
          },
        },
        {
          path: "/published-details-done/:id",
          name: "รายละเอียดรับค่าตีพิมพ์ผลงาน (เสร็จสิ้น/อนุมัติ)",
          component: PublishedDetail,
          beforeEnter: (to, from, next) => {
            if (from.path == "/approve-done") {
              return next();
            }
            next("/approve-done");
          },
        },
        
      
    ],
  },




];

export default routes;
