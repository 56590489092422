<template>
    <div class="flex flex-wrap">
        <div class="w-full bg-white p-4 boxShadow-main" :style="{ borderRadius: '10px' }">
            <form @submit.prevent="submitForm">
                <a-row :gutter="[16]">
                    <a-col :lg="24" :md="24" :xs="24" class="gutter-row">

                        <a-row :gutter="[16, 16]">
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนหัวของหนังสือมอบอำนาจ</b></a-col>
                            <a-col :lg="6" :md="6" :xs="24">
                                <TextInputUCVue :v-model="letter_code" :label="'เลขที่หนังสือ'"
                                    :placeholder="'เลขที่หนังสือ'" :textValues="ReportData?.letter_code"
                                    :onChanged="(v) => (this.Values.letter_code = v)" />
                            </a-col>
                            <a-col :lg="18" :md="18" :xs="24">
                                <label>มอบอำนาจเกี่ยวกับ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="power_topic" 
                                    :placeholder="'ระบุมอบอำนาจเกี่ยวกับ'" :textValues="ReportData?.power_topic"
                                    :onChanged="(v) => (this.Values.power_topic = v)" />
                                    <span v-if="errors.power_topic" class="error-message">{{ errors.power_topic }}</span>
                            </a-col>

                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนเนื้อหาของบันทึกข้อความ</b></a-col>
                            <a-col :lg="12" :md="12" :xs="12">
                                <label>ชื่อผู้รับมอบอำนาจ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="power_for" 
                                    :placeholder="'ระบุชื่อผู้รับมอบอำนาจ'" :textValues="ReportData?.power_for"
                                    :onChanged="(v) => (this.Values.power_for = v)" />
                                    <span v-if="errors.power_for" class="error-message">{{ errors.power_for }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label  class="mr-1">ตำแหน่ง<span class="text-danger">*</span></label>
                                <span style="color:#6868a8; font-weight:600;"><i>(ข้อมูลเดิม : {{ this.Values.econ_report_position_id_result }})</i></span>
                                <AutoCompleteUC :v-model="econ_report_position_id" 
                                    placeholder="ระบุตำแหน่ง" :options="econ_report_position_id"
                                    :onChanged="(v) => (Values.econ_report_position_id = v)"
                                    :optionValue="Values.econ_report_position_id"
                                    @select="onOptionSelected" />
                                    <span v-if="errors.econ_report_position_id" class="error-message">{{ errors.econ_report_position_id }}</span>
                            </a-col>
                            <!-- ตรงนีต้องแก้ไขให้เป็น Multiple Selected (เลือกได้มากกว่า 1) -->
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label  class="mr-1">เป็นผู้มีอำนาจในการ<span class="text-danger">*</span></label>
                                <span style="color:#6868a8; font-weight:600;"><i>(ข้อมูลเดิม : {{ this.Values.econ_report_power_id_result }})</i></span>
                                <!-- <AutoCompleteUC :v-model="econ_report_power_id"
                                    placeholder="ระบุเป็นผู้มีอำนาจในการ" :options="econ_report_power_id"
                                    :onChanged="(v) => (Values.econ_report_power_id =
                                     v)"
                                    :optionValue="Values.econ_report_power_id" /> -->
                                    <a-tree-select
                                    :v-model="econ_report_power_id" 
                                    :tree-data="econ_report_power_id"
                                    tree-checkable
                                    allow-clear
                                    :show-checked-strategy="SHOW_PARENT"
                                    :placeholder="'ระบุเป็นผู้มีอำนาจในการ'"
                                    tree-node-filter-prop="label"
                                    class="w-full"
                                    :options="econ_report_power_id"
                                    @change="onChanged"     
                                  ></a-tree-select>
                                    <span v-if="errors.econ_report_power_id" class="error-message">{{ errors.econ_report_power_id }}</span>
                            </a-col>
                            <!-- ตรงนีต้องแก้ไขให้เป็น Multiple Selected (เลือกได้มากกว่า 1) -->
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>ภายใต้โครงการ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="power_project" 
                                    :placeholder="'ภายใต้โครงการ'" :textValues="ReportData?.power_project"
                                    :onChanged="(v) => (this.Values.power_project = v)" />
                                    <span v-if="errors.power_project" class="error-message">{{ errors.power_project }}</span>
                            </a-col>


                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนท้ายของบันทึกข้อความ</b></a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label class="mr-1">ผู้ลงนามหนังสือมอบอำนาจ<span class="text-danger">*</span></label>
                                <span style="color:#6868a8; font-weight:600;"><i>(ข้อมูลเดิม : {{ this.Values.letter_sign_id_result }})</i></span>
                                <AutoCompleteUC :v-model="letter_sign_id"
                                    placeholder="ระบุผู้ลงนามบันทึกข้อความ" :options="letter_sign_id"
                                    :onChanged="(v) => (Values.letter_sign_id = v)"
                                    :optionValue="Values.letter_sign_id" />
                                    <span v-if="errors.letter_sign_id" class="error-message">{{ errors.letter_sign_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label class="mr-1">ตำแหน่งผู้ลงนามหนังสือมอบอำนาจ<span class="text-danger">*</span></label>
                                <span style="color:#6868a8; font-weight:600;"><i>(ข้อมูลเดิม : {{ this.Values.letter_sign_position_id_result }})</i></span>
                                <AutoCompleteUC :v-model="letter_sign_position_id" 
                                    placeholder="ระบุตำแหน่งผู้ลงนามบันทึกข้อความ" :options="letter_sign_position_id"
                                    :onChanged="(v) => (Values.letter_sign_position_id = v)"
                                    :optionValue="Values.letter_sign_position_id" />
                                    <span v-if="errors.letter_sign_position_id" class="error-message">{{ errors.letter_sign_position_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <label class="mr-1">วิธีการลงนาม<span class="text-danger">*</span></label>
                                <span style="color:#6868a8; font-weight:600;"><i>(ข้อมูลเดิม : {{ this.Values.power_action_id_result }})</i></span>
                                <AutoCompleteUC :v-model="power_action_id" placeholder="ระบุลงนาม"
                                    :options="power_action_id" :onChanged="(v) => (Values.power_action_id = v)"
                                    :optionValue="Values.power_action_id" />
                                    <span v-if="errors.power_action_id" class="error-message">{{ errors.power_action_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">

                                <DatePickkerUCVue :label="'เลขที่หนังสือ'" :dueDateValue="Values.letter_sign_date"
                                    :onChangeDate="(v) => (this.Values.letter_sign_date = v)" />


                            </a-col>


                            <a-col :lg="24" :md="24" :xs="24">
                                <br />
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>เอกสารแนบ (ถ้ามี)</b>
                                <p :style="{ fontSize: '14px', opacity: '0.6' }">
                                    ขนาดไฟล์ไม่เกิน 8 MB
                                </p>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <div v-if="this.Values.attach_1_result != null">
                                    <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${this.Values.attach_1_result}`"  target="_blank" style="display: ruby;">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                                        <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                                      </svg>
                                      เอกสารแนบที่ 1
                                    </a>
                                    <button class="badge" style="background-color:red; border-radius:40px"  type="button"
                                    @click="onClickDelete('econ_2','attach_1')" >
                                        <BaseIconVue name="rejectIcon" />
                                        ลบไฟล์
                                    </button><br>
                                </div>
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange1" />
                                    เอกสารแนบ 1 (ถ้ามี) : <span v-if="selectedFile1" style="color: #4691f6;">{{
                                        selectedFile1.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />
                                </label>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <div v-if="this.Values.attach_2_result != null">
                                    <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${this.Values.attach_2_result}`"  target="_blank" style="display: ruby;">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                                        <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                                      </svg>
                                      เอกสารแนบที่ 2
                                    </a>
                                    <button class="badge" style="background-color:red; border-radius:40px"  type="button"
                                    @click="onClickDelete('econ_2','attach_2')" >
                                        <BaseIconVue name="rejectIcon" />
                                        ลบไฟล์
                                    </button><br>
                                </div>
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange2" />
                                    เอกสารแนบ 2 (ถ้ามี) : <span v-if="selectedFile2" style="color: #4691f6;">{{
                                        selectedFile2.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />
                                </label>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <div v-if="this.Values.attach_3_result != null">
                                    <a :href="`https://ert.econ.cmu.ac.th/public/uploads/econ-report/${this.Values.attach_3_result}`"  target="_blank" style="display: ruby;">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="15" height="15">
                                        <path fill="#B197FC" d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                                      </svg>
                                      เอกสารแนบที่ 3
                                    </a>
                                    <button class="badge" style="background-color:red; border-radius:40px"  type="button"
                                    @click="onClickDelete('econ_2','attach_3')" >
                                        <BaseIconVue name="rejectIcon" />
                                        ลบไฟล์
                                    </button><br>
                                </div>
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange3" />
                                    เอกสารแนบ 3 (ถ้ามี) : <span v-if="selectedFile3" style="color: #4691f6;">{{
                                        selectedFile3.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;" />
                                </label>
                            </a-col>

                        </a-row>
                    </a-col>

                </a-row>




                <a-row :gutter="[16]">
                    <a-col :lg="8" :md="12" :xs="24" class="gutter-row">
                        <a-row :gutter="[16, 16]" class="h-full">
                            <a-col :md="12" v-if="this.Values.is_reject == 0">
                                <ButtonUCVue type="submit" :header="'test'" :label="'บันทึกข้อมูล'" :isBg="true"
                                    :iconName="'SaveIcon'" :iconColor="'#FFF'" />
                            </a-col>
                            <a-col :md="16" v-else-if="this.Values.is_reject == 1">
                                <ButtonUCVue type="submit" :header="'is_reject'" :label="'บันทึกและยื่นขออนุมัติข้อมูล'" :isBg="true"
                                :iconName="'waitingIcon'" :iconColor="'#FFF'" />
                            </a-col> 
                        </a-row>
                    </a-col>
                </a-row>
            </form>
        </div>
        <!-- Button Actions -->
        <div class="w-full bg-white mt-2 p-4 boxShadow-main flex gap-4 items-center justify-end sticky bottom-0"
            :style="{ borderRadius: '10px', height: '100%' }">
            <div :style="{ minWidth: '10rem' }">
                <ButtonUCVue label="ย้อนกลับ" :onClicked="backPage" />
            </div>
        </div>
        <!-- <button @click="checkFormData">Check</button> -->
    </div>
</template>
<script>
import ButtonUCVue from "../../components/ButtonUC.vue";
import TextInputUCVue from "../../components/TextInputUC.vue";
import AutoCompleteUC from "../../components/AutoCompleteUC.vue";
import DatePickkerUCVue from "../../components/DatePickkerUC.vue";
import BaseIcon from "../../components/Icon/BaseIcon.vue";
import BaseIconVue from "../../components/Icon/BaseIcon.vue";

import { TreeSelect } from "ant-design-vue";
import { HTTP } from "../../http-common";
import dayjs from "dayjs";
import moment from "moment";


export default {
    name: "ReportSignPositionEdit",
    components: {
        TextInputUCVue,
        ButtonUCVue,
        AutoCompleteUC,
        DatePickkerUCVue,
        BaseIcon,
        BaseIconVue,
   

    },
    mounted() {
        this.fetchSignList();
        if (this.$router.currentRoute._rawValue.params.id) {
            this.id = this.$router.currentRoute._rawValue.params.id;
            this.getReportPublications(this.id);
        }
        this.$store.commit("setChild", this.$router.currentRoute._value);
    },


    // mounted() {
    //     if (this.$router.currentRoute._rawValue.params.project_id) {
    //     this.project_id = this.$router.currentRoute._rawValue.params.project_id;
    //     this.getResearchById(this.project_id);
    //     }
    //     this.$store.commit("setChild", this.$router.currentRoute._value);
    // },

    unmounted() {
        //this.$store.commit("setClearFileSave");
        this.$store.commit("setChild", "");
    },

    methods: {
        async checkFormData(){
        // console.log(this.ReportData);
        //console.log(this.Values);
       },
       onChanged(value) {
        this.Values.econ_report_power_id = value;
        // console.log('Selected value:', value);  // แสดงค่าที่ถูกเลือกใน console
        },
        
        handleFileChange1(event) {
            this.Values.attach_1 = event.target.files[0];
            this.selectedFile1 = this.Values.attach_1;
        },
        handleFileChange2(event) {
            this.Values.attach_2 = event.target.files[0];
            this.selectedFile2 = this.Values.attach_2;
        },
        handleFileChange3(event) {
            this.Values.attach_3 = event.target.files[0];
            this.selectedFile3 = this.Values.attach_3;
        },

        async changedDate(date) {
            this.Values = {
                ...this.Values,
                letter_project_begin: date.startDate,
                letter_project_end: date.endDate,
            };
        },
        async fetchSignList() {
            let loading = this.$loading.show();
            const { data } = await HTTP.post("/getReportSignList");
            this.letter_sign_id = data.sign_id;
            this.letter_sign_position_id = data.sign_position_id;
            this.econ_report_position_id = data.econ_report_position_id;
            this.econ_report_power_id = data.econ_report_power_id;
            this.power_action_id = data.power_action_id;

            loading.hide();
        },
        onClickDelete(type,files) {
            this.$swal
            .fire({
            title: "ยืนยันการลบไฟล์",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#ED4040",
            cancelButtonColor: "#FDECEC",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            customClass: {
                cancelButton:
                "cancelButtonOnReject",
            },
            })
            .then((result) => {
            if (result.isConfirmed) {
                this.DeleteFile(type,files);
            }
            });
        },

        async DeleteFile(type,files) {
            let loader = this.$loading.show();
            let body = {
                id: this.id,
                type_id: type,
                files_id: files,
            };
            const { data } = await HTTP.post("/DeleteFile", body);
            // alert(data);
            if (!data.error) {
                loader.hide();
                this.$swal.fire({
                title: "ทำรายการสำเร็จ",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
                width: 300,
                });

                setTimeout(() => {
                this.getReportPublications(this.id);
                }, 2000);
            }
        },
        async getReportPublications(id) {
            let loader = this.$loading.show();
            try {
                const { data } = await HTTP.get(`/getReportPublications/${id}`);

                if (data.data && data.data.length > 0) {
                    this.Values = data?.data[0]; /* Fetch to Values */

                    this.Values.letter_sign_id_result = data.data[0].sign_id;
                    this.Values.letter_sign_position_id_result = data.data[0].sign_position_id;
                    this.Values.econ_report_position_id_result = data.data[0].report_position_id;
                    this.Values.econ_report_power_id_result = data.data[0].report_power_id;
                    this.Values.power_action_id_result = data.data[0].econ_power_action_id;
                    this.Values.attach_1_result = data.data[0].econ_attach_1;
                    this.Values.attach_2_result = data.data[0].econ_attach_2;
                    this.Values.attach_3_result = data.data[0].econ_attach_3;

                    if (data.data[0].letter_sign_date) {
                        let return_letter_sign_date = data.data[0].letter_sign_date;
                        let convert_letter_sign_date = dayjs(return_letter_sign_date, 'YYYY-MM-DD').locale("th");
                        this.Values.letter_sign_date = convert_letter_sign_date;
                    } else {
                        this.Values.letter_sign_date = "";
                    }

                    this.ReportData = data?.data[0];
                    loader.hide();
                } else {
                    this.ReportData = null;
                    loader.hide();
                }
            } catch (error) {
                console.error(error.message);

            }
        },

        async backPage() {
            this.$router.push(`/report-Publications`);
        },
        async handleClear() {
            this.ReportData = {
                letter_code: "",
                power_topic: "",
                power_for: "",
                econ_report_position_id: "",
                econ_report_power_id: "",
                power_project: "",
                power_action_id: "",
                letter_sign_id: "",
                letter_sign_position_id: "",
                letter_sign_date: "",
            };



        },
        async submitForm() {

            const formData = new FormData();
            formData.append("id", this.ReportData?.id);
            formData.append("letter_code", this.Values.letter_code);
            formData.append("power_topic", this.Values.power_topic);
            formData.append("power_for", this.Values.power_for);
            formData.append("econ_report_position_id", this.Values.econ_report_position_id);
            formData.append("econ_report_power_id", this.Values.econ_report_power_id);
            formData.append("power_project", this.Values.power_project);
            formData.append("power_action_id", this.Values.power_action_id);
            formData.append("letter_sign_id", this.Values.letter_sign_id);
            formData.append("letter_sign_position_id", this.Values.letter_sign_position_id);
            formData.append("letter_sign_date", moment(new Date(this.Values.letter_sign_date)).format("YYYY-MM-DD"));
            formData.append("is_reject", this.Values.is_reject);


            /* has :: attach_1 */
            if (this.Values.attach_1) {
                formData.append("attach_1", this.Values.attach_1);
            } else {
                console.log('No attach_1 file selected.');
            }

            /* has :: attach_2 */
            if (this.Values.attach_2) {
                formData.append("attach_2", this.Values.attach_2);
            } else {
                console.log('No attach_2 file selected.');
            }

            /* has :: attach_3 */
            if (this.Values.attach_3) {
                formData.append("attach_3", this.Values.attach_3);
            } else {
                console.log('No attach_3 file selected.');
            }

            try {

                let isValid = true;
                if (!this.Values.power_topic) {
                    this.errors.power_topic = 'ระบุมอบอำนาจเกี่ยวกับ';
                    isValid = false;
                }
                if (!this.Values.power_for) {
                    this.errors.power_for = 'ระบุชื่อผู้รับมอบอำนาจ';
                    isValid = false;
                }
                if (!this.Values.econ_report_position_id) {
                    this.errors.econ_report_position_id = 'ระบุตำแหน่ง';
                    isValid = false;
                }
                if (!this.Values.econ_report_power_id) {
                    this.errors.econ_report_power_id = 'ระบุเป็นผู้มีอำนาจในการ';
                    isValid = false;
                }
                if (!this.Values.power_project) {
                    this.errors.power_project = 'ระบุภายใต้โครงการ';
                    isValid = false;
                }
                if (!this.Values.letter_sign_id) {
                    this.errors.letter_sign_id = 'ระบุผู้ลงนามบันทึกข้อความ';
                    isValid = false;
                }
                if (!this.Values.letter_sign_position_id) {
                    this.errors.letter_sign_position_id = 'ระบุตำแหน่งผู้ลงนามบันทึกข้อความ';
                    isValid = false;
                }
                if (!this.Values.power_action_id) {
                    this.errors.power_action_id = 'ระบุวิธีการลงนาม';
                    isValid = false;
                }
                if (isValid) {



                await HTTP.post('/UpdateReportPublications', formData, {
                })
                .then(response => {
                    this.$swal.fire({
                        title: "บันทึกสำเร็จ",
                        width: 300,
                        height: 300,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                    }).then((result) => {
                        this.$router.push(`/report-publications`);
                    });
                })
                .catch(error => {
                    console.error('Error uploading file:'+ error);
                });

                }else{
                    console.error('There was an validate!', error);
                }



            } catch (error) {
                console.error('There was an error!', error);
            }

        },

    },



    data() {
        return {
            SHOW_PARENT: TreeSelect.SHOW_PARENT,
            econ_report_power_id: [],
            errors: {},      // เก็บ error message ของ validation
            selectedFile1: null,
            selectedFile2: null,
            selectedFile3: null,
            ReportData: null,
            loader: "",
            letter_sign_id: [],
            letter_sign_position_id: [],
            econ_report_position_id: [],
            power_action_id: [],
            Values: {

                letter_code: null,
                power_topic: "",
                power_for: "",
                econ_report_position_id: "",
                econ_report_power_id: "",
                power_project: "",
                power_action_id: "",
                letter_sign_id: "",
                letter_sign_position_id: "",
                letter_sign_date: null,
                attach_1: "",
                attach_2: "",
                attach_3: "",
                letter_sign_id_result: "",
                letter_sign_position_id_result: "",
                econ_report_position_id_result: "",
                econ_report_power_id_result: "",
                power_action_id_result: "",
                attach_1_result: "",
                attach_2_result: "",
                attach_3_result: "",

            },
        };
    },



};
</script>

<style scoped>
.text-danger{
    color:red;
}
.error-message{
    color:red;
    font-size: 12px;
}
.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border: 2px solid #4CAF50;
    border-radius: 50px;
    border-color: #ddd;
    color: ddd;
    font-weight: bold;
    width: 100%;
}

.custom-file-upload input[type="file"] {
    display: none;
}

button {
    margin-top: 10px;
    padding: 6px 12px;
    border: none;
    background-color: #2196F3;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0b7dda;
}
</style>