<template>
  <Transition name="sidebarSlide">
    <nav v-if="$store.state.sideBar"
      class="left-0 block fixed top-0 bottom-0 md:overflow-y-auto flex-row flex-nowrap md:overflow-hidden shadow-xl bg-white md:w-64 w-56 z-10 py-4 px-4">
      <div
        class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        <div class="flex justify-center">
          <img src="../../assets/EconLogo2.png" alt="logo" :style="{ height: '40px' }" />
        </div>
        <div
          class="flex flex-col items-stretch opacity-100 md:relative md:mt-8 mt-20 shadow-none absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-full flex-1 rounded md:p-0 p-2">
          <ul class="flex-col min-w-full flex list-none">
            <li class="items-center">
              <router-link to="/research-list" v-slot="{ href, navigate, isActive }">
                <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                  isActive || $store.state.routeName.href == href
                    ? researchListLinkStyle
                    : defaultLinkStyle,
                ]">
                  <i class="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M2.67 2.44465C2.58625 2.34686 2.53229 2.22712 2.51452 2.09961C2.49674 1.9721 2.5159 1.84216 2.56971 1.7252C2.62353 1.60824 2.70974 1.50916 2.81814 1.43971C2.92655 1.37025 3.05259 1.33333 3.18133 1.33331H11.5C11.894 1.33331 12.2841 1.41091 12.6481 1.56167C13.012 1.71244 13.3427 1.93342 13.6213 2.21199C13.8999 2.49057 14.1209 2.82129 14.2716 3.18526C14.4224 3.54924 14.5 3.93935 14.5 4.33331C14.5 4.72728 14.4224 5.11739 14.2716 5.48136C14.1209 5.84534 13.8999 6.17606 13.6213 6.45463C13.3427 6.73321 13.012 6.95419 12.6481 7.10495C12.2841 7.25572 11.894 7.33331 11.5 7.33331H3.18133C3.05259 7.3333 2.92655 7.29638 2.81814 7.22692C2.70974 7.15746 2.62353 7.05838 2.56971 6.94142C2.5159 6.82447 2.49674 6.69453 2.51452 6.56702C2.53229 6.43951 2.58625 6.31976 2.67 6.22198L3.17267 5.63531C3.48341 5.27282 3.65423 4.81111 3.65423 4.33365C3.65423 3.85619 3.48341 3.39448 3.17267 3.03198L2.67 2.44465ZM2.5 11C2.5 10.2043 2.81607 9.44127 3.37868 8.87866C3.94129 8.31605 4.70435 7.99998 5.5 7.99998H13.8187C13.9474 7.99999 14.0735 8.03692 14.1819 8.10637C14.2903 8.17583 14.3765 8.27491 14.4303 8.39187C14.4841 8.50883 14.5033 8.63876 14.4855 8.76627C14.4677 8.89379 14.4137 9.01353 14.33 9.11131L13.8267 9.69798C13.5159 10.0605 13.3451 10.5222 13.3451 10.9996C13.3451 11.4771 13.5159 11.9388 13.8267 12.3013L14.33 12.888C14.414 12.9857 14.4681 13.1056 14.486 13.2332C14.5039 13.3608 14.4848 13.4909 14.4309 13.608C14.3771 13.725 14.2908 13.8242 14.1823 13.8937C14.0737 13.9632 13.9475 14.0001 13.8187 14H5.5C4.70435 14 3.94129 13.6839 3.37868 13.1213C2.81607 12.5587 2.5 11.7956 2.5 11Z"
                        :fill="[
                          isActive || $store.state.routeName.href == href
                            ? '#FFFFFF'
                            : '#333436',
                        ]" />
                    </svg>
                  </i>
                  รายการโครงการวิจัย
                </a>
              </router-link>
            </li>

            <li class="items-center" v-if="$store.state.user.role == 'supporter'">
              <router-link to="/research-check" v-slot="{ href, navigate, isActive }">
                <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                  isActive || $store.state.routeName.href == href
                    ? researchListLinkStyle
                    : defaultLinkStyle,
                ]">
                  <i class="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path
                        d="M3.16667 2C2.79848 2 2.5 2.29848 2.5 2.66667V13.3333C2.5 13.7015 2.79848 14 3.16667 14H13.8333C14.2015 14 14.5 13.7015 14.5 13.3333V7.04763C14.5 6.75066 14.141 6.60194 13.931 6.81193L12.6289 8.11401C12.5664 8.17652 12.5312 8.26131 12.5312 8.34971V11.6979C12.5312 11.882 12.382 12.0312 12.1979 12.0312H4.80208C4.61799 12.0312 4.46875 11.882 4.46875 11.6979V4.30208C4.46875 4.11799 4.61799 3.96875 4.80208 3.96875H8.15029C8.23869 3.96875 8.32348 3.93363 8.38599 3.87112L9.68807 2.56904C9.89806 2.35905 9.74934 2 9.45237 2H3.16667ZM13.0358 2.38733C12.9056 2.25715 12.6945 2.25716 12.5644 2.38735L8.69398 6.25837C8.56381 6.38856 8.35274 6.38857 8.22256 6.25839L7.14276 5.17859C7.01256 5.04839 6.80146 5.04841 6.6713 5.17864L5.5518 6.29865C5.42167 6.42884 5.4217 6.63988 5.55188 6.77003L7.98681 9.2044C8.24716 9.4647 8.66923 9.46468 8.92956 9.20435L10.049 8.08488L14.1552 3.97808C14.2853 3.8479 14.2853 3.63686 14.1552 3.5067L13.0358 2.38733Z"
                        :fill="[
                          isActive || $store.state.routeName.href == href
                            ? '#FFFFFF'
                            : '#333436',
                        ]" />
                    </svg>
                  </i>
                  ตรวจสอบโครงการวิจัย
                </a>
              </router-link>
            </li>
            <!-- v-if="$store.state.user.role == 'supporter'" No -->
            <nav v-if="this.approves_setting.includes($store.state.user.email)">
              <hr>
              <li class="items-center">
                <a href="#report_menu" id="menu_report" data-bs-toggle="collapse"
                  class="uppercase px-3 py-4 font-bold flex items-center">สร้างหนังสือ/บันทึก</a>
                <ul class="flex-col min-w-full flex list-none" id="report_menu" data-bs-parent="#menu_report">
                  <li class="items-center">
                    <router-link to="/report-researchs" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 384 512">
                            <path
                              d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#333436',
                              ]" />
                          </svg></i>
                        ขอเบิกเงินโครงการวิจัย
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/report-publications" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 384 512">
                            <path
                              d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#333436',
                              ]" />
                          </svg></i>
                        หนังสือมอบอำนาจ
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/report-authorizes" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 384 512">
                            <path
                              d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#333436',
                              ]" />
                          </svg></i>
                        หนังสือเชิญเข้าร่วม/ประชุม

                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/report-published" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 384 512">
                            <path
                              d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#333436',
                              ]" />
                          </svg></i>
                        ขอรับค่าตีพิมพ์ผลงาน

                      </a>
                    </router-link>
                  </li>

                </ul>
              </li>
            

              <hr>
              <li class="items-center">
                <a href="#report_menu" id="menu_report" data-bs-toggle="collapse"
                  class="uppercase px-3 py-4 font-bold flex items-center">ติดตามสถานะหนังสือ/บันทึก</a>
                <ul class="flex-col min-w-full flex list-none" id="report_menu" data-bs-parent="#menu_report">
                  <li class="items-center">
                    <router-link to="/approve-wait" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512"
                            fill="#74C0FC">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#FFD43B',
                              ]" />
                          </svg>
                        </i>
                        รอการพิจารณา/อนุมัติ
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/approve-reject" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512"
                            fill="#74C0FC">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#ff3d3d',
                              ]" />
                          </svg>
                        </i>
                        ถูกตีกลับ-ให้แก้ไข
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/approve-done" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512"
                            fill="#74C0FC">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#52be80',
                              ]" />
                          </svg>
                        </i>
                        เสร็จสิ้น/อนุมัติ
                      </a>
                    </router-link>
                  </li>

                </ul>
              </li>
              </nav>

              <nav v-if="this.approves_setting.includes($store.state.user.email)">
              <hr>
              <li class="items-center">
                <a href="#report_menu" id="menu_report" data-bs-toggle="collapse"
                  class="uppercase px-3 py-4 font-bold flex items-center">สำหรับผู้มีสิทธิ์พิจารณา/อนุมัติ</a>
                <ul class="flex-col min-w-full flex list-none" id="report_menu" data-bs-parent="#menu_report">
                  <li class="items-center">
                    <!-- <router-link to="/approve-form" class="uppercase px-3 py-4 font-bold flex items-center">
                                  <i class="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512"><path fill="#74C0FC" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z"/></svg></i>
                                  พิจารณา/อนุมัติ
                                </router-link> -->
                    <router-link to="/report-researchs-approve" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 512 512"
                            fill="#74C0FC">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#74C0FC',
                              ]" />
                          </svg>
                        </i>
                        ขอเบิกเงินโครงการวิจัย
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/report-publications-approve" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 512 512"
                            fill="#74C0FC">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#74C0FC',
                              ]" />
                          </svg>
                        </i>
                        หนังสือมอบอำนาจ
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/report-authorizes-approve" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 512 512"
                            fill="#74C0FC">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#74C0FC',
                              ]" />
                          </svg>
                        </i>
                        หนังสือเชิญเข้าร่วม/ประชุม
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/report-published-approve" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 512 512"
                            fill="#74C0FC">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#74C0FC',
                              ]" />
                          </svg>
                        </i>
                        ขอรับค่าตีพิมพ์ผลงาน
                      </a>
                    </router-link>
                  </li>
                </ul>
              </li>
              </nav>

              <nav v-if="$store.state.user.role == 'supporter'">
              <hr>
              <li class="items-center">
                <a href="#setting_menu" id="menu_setting" data-bs-toggle="collapse"
                  class="uppercase px-3 py-4 font-bold flex items-center">เมนูตั้งค่า (ผู้ดูแลระบบ)</a>
                <ul class="flex-col min-w-full flex list-none" id="setting_menu" data-bs-parent="#menu_setting">
                  <li class="items-center">
                    <router-link to="/setting-sign" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 512 512"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#333436',
                              ]" />
                          </svg>
                        </i>
                        ตั้งค่าผู้ลงนาม
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/setting-sign-position" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 512 512"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#333436',
                              ]" />
                          </svg>
                        </i>
                        ตั้งค่าตำแหน่ง
                      </a>
                    </router-link>
                  </li>
                  <li class="items-center">
                    <router-link to="/setting-approvers" v-slot="{ href, navigate, isActive }">
                      <a :href="href" @click="navigate" class="uppercase px-3 py-4 font-bold flex items-center" :style="[
                        isActive || $store.state.routeName.href == href
                          ? researchListLinkStyle
                          : defaultLinkStyle,
                      ]">
                        <i class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 512 512"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                              :fill="[
                                isActive || $store.state.routeName.href == href
                                  ? '#FFFFFF'
                                  : '#333436',
                              ]" />
                          </svg>
                        </i>
                        ตั้งค่าลำดับการอนุมัติ
                      </a>
                    </router-link>
                  </li>
                </ul>
              </li>
              </nav>
          </ul>
        </div>
      </div>
    </nav>
  </Transition>
</template>

<script>
import { HTTP } from "../../http-common";

export default {

  computed: {
    sideBar() {
      return this.$store.state.sideBar;
    },
    userRole() {
      return this.$store.state.user.role;
    },

    researchListLinkStyle() {
      return {
        backgroundColor: "#F06599",
        color: "#FFFFFF",
        borderRadius: "40px",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
      };
    },
    defaultLinkStyle() {
      return {
        color: "#333436",
      };
    },
  },

  components: {},
  mounted() {
    this.fetchApproveData();
  },
  methods: {
    async fetchApproveData() {
      const { data } = await HTTP.post("/getReportApproversSetting");
      if (data.approves_setting && data.approves_setting.length > 0) {
        this.approves_setting = data.approves_setting;
      }else{
        this.approves_setting = "";
      }
    },
    
  },

  data() {
    return {
      approves_setting: [],
    };
  },
  

  
};



// export default {
//   computed: {
//     sideBar() {
//       return this.$store.state.sideBar;
//     },
//     userRole() {
//       return this.$store.state.user.role;
//     },

//     researchListLinkStyle() {
//       return {
//         backgroundColor: "#F06599",
//         color: "#FFFFFF",
//         borderRadius: "40px",
//         boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
//       };
//     },
//     defaultLinkStyle() {
//       return {
//         color: "#333436",
//       };
//     },
//   },
//   components: {},
// };
</script>
