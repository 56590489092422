<template>
  <div class="flex flex-wrap">
    <div class="w-full flex flex-col gap-4">

          <TableReportAuthorizesApprove
              :columnsList="columns"
              :tableData="dataValues"
              :onClickedRows="onClickRows"
              :paginationConfig="paginationConfig"
              :filterTable="filterTable"
              :sortBy="sort_by"
              :sortChanged="
                (v) => {
                  sort_by = v;
                  fetchReportAuthorizesList();
                }
              "
            />
      </div>
    </div>
  </template>
  <script>
    import SearchBoxVue from "../../components/SearchBox/SearchBox_Authorizes.vue";
    import ButtonUCVue from "../../components/ButtonUC.vue";
  import TableReportAuthorizesApprove from "../../components/TableReportAuthorizesApprove.vue";

  
  import { HTTP } from "../../http-common";
  import DateFunction from "../../services/DateFunction";
  
  export default {
    name: "ReportAuthorizesList",
    components: {
      TableReportAuthorizesApprove,
    },
    mounted() {
      this.fetchReportAuthorizesList();
      this.$store.commit("setBread", this.$router.currentRoute._value);
    },
    methods: {
      async fetchReportAuthorizesList() {
        let loading = this.$loading.show();

        let body = {
          limit: this.paginationConfig.pageSize,
          offset:
            this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
            search_value: {
          ...this.search_value,
         
        },
          
        };

        const { data } = await HTTP.post("/getReportAuthorizesApprove", body);

        this.research_name = data.research_name;
        this.letter_project_topic = data.letter_project_topic;


        if (data.data && data.data.length > 0) {
          this.dataValues = data.data;
          this.paginationConfig.total = data.total;

          loading.hide();
        } else {
          loading.hide();
          this.dataValues = [];
          this.paginationConfig.total = 0;
        }
        loading.hide();
      },

      onClickRows(data) {
        this.$router.push(`/authorizes-details-approve/${data.id}`);
      },
      handlePageChange(page) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: page,
        };
        this.fetchReportAuthorizesList();
      },

      handlePageSizeChange(current, pageSize) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: current,
          pageSize: pageSize,
        };
      },
    },

    data() {
      return {
        loader: "",
        search_value: null,
        sortOptions: 0,

        columns: [
          {
            title: "ชื่องานวิจัย",
            dataIndex: "letter_project_topic",
            width: 250,
          },
          {
            title: "ชื่อหัวหน้าโครงการ",
            dataIndex: "letter_project_head",
            width: 150,
          },
          {
            title: "ชื่อผู้ถูกเรียนเชิญ",
            dataIndex: "letter_invited",
            width: 150,
          },
          {
            title: "สังกัดผู้ถูกเรียนเชิญ",
            dataIndex: "letter_invited_organization",
            width: 150,
          },
          {
            title: "เลขที่หนังสือ/ลงวันที่",
            dataIndex: "letter_code",
            width: 150,
          },
          {
            title: "พิจารณา/อนุมัติ",
            dataIndex: "approve_status",
            align: "center",
            width: 80,
          },
        ],
     
        paginationConfig: {
          total: 0,
          pageSize: 10,
          current: 1,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 50, 100],
          onChange: this.handlePageChange,
          onShowSizeChange: this.handlePageSizeChange,
        },

        dataValues: [],
        letter_project_topic: [],
        letter_project_begin: [],
        letter_project_end: [],
        dataLength: 0,
        research_name: [],
      };
    },
    

    
  };
  </script>
  
  <style scoped></style>