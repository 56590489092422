<template>
  <div class="flex flex-wrap">
    <div class="w-full flex flex-col gap-4">

        <SearchBoxVue
        :onChanged="(v) => (search_value = v)"
        :onClickedSearch="fetchReportAuthorizesList"
        :researchNameOptions="letter_project_topic"
        :researchLeadOptions="research_name"
      
        
        />
        

          <a-row :gutter="[24]">
            <a-col :lg="10" :md="24" :xs="24" class="gutter-row">
              <a-row :gutter="[16, 16]">
                <a-col :lg="10" :md="10" :xs="8">  
                  <ButtonUCVue 
                    :header="'test'"
                    :label="'เพิ่มข้อมูล'"
                    :isBg="true"
                    :onClick="onClickAdd"
                    :iconName="'AddIcon'"
                    :iconColor="'#FFF'"
                  />
                </a-col>
              </a-row>
            </a-col>

          </a-row>
    
          <TableReportAuthorizes
              :columnsList="columns"
              :tableData="dataValues"
              :EditClicked="onClickEdit"
              :DeleteClicked="showDeletePopup"
              :ApproveClicked="showApprovePopup"
              :ReportPDF="ReportPDF"
              :ReportWord="ReportWord"
              :paginationConfig="paginationConfig"
              :filterTable="filterTable"
              :sortBy="sort_by"
              :sortChanged="
                (v) => {
                  sort_by = v;
                  fetchReportAuthorizesList();
                }
              "
            />
      </div>
    </div>
  </template>
  <script>
    import SearchBoxVue from "../../components/SearchBox/SearchBox_Authorizes.vue";
    import ButtonUCVue from "../../components/ButtonUC.vue";
  import TableReportAuthorizes from "../../components/TableReportAuthorizes.vue";

  
  import { HTTP } from "../../http-common";
  import DateFunction from "../../services/DateFunction";
  
  export default {
    name: "ReportAuthorizesList",
    components: {
      ButtonUCVue,
      TableReportAuthorizes,
      SearchBoxVue,
  
     
    },
    mounted() {
      this.fetchReportAuthorizesList();
      this.$store.commit("setBread", this.$router.currentRoute._value);
    },
    methods: {
      async fetchReportAuthorizesList() {
        let loading = this.$loading.show();

        let body = {
          limit: this.paginationConfig.pageSize,
          offset:
            this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
            search_value: {
          ...this.search_value,
          letter_project_begin: DateFunction.getSearchDate(
            this.search_value.letter_project_begin
          ),
          letter_project_end: DateFunction.getSearchDate(
            this.search_value.letter_project_end
          ),
        },
          
        };

        const { data } = await HTTP.post("/getReportAuthorizesList", body);

        this.research_name = data.research_name;
        this.letter_project_topic = data.letter_project_topic;
        this.is_reject = data.is_reject;


        if (data.data && data.data.length > 0) {
          this.dataValues = data.data;
          this.paginationConfig.total = data.total;

          loading.hide();
        } else {
          loading.hide();
          this.dataValues = [];
          this.paginationConfig.total = 0;
        }
        loading.hide();
      },

      onClickAdd() {
        this.$router.push(`/report-authorizes-add`);
      },
      onClickEdit(data) {
        this.$router.push(`/report-authorizes-edit/${data.id}`);
      },
      showDeletePopup(record) {
        this.$swal
          .fire({
            iconHtml:
              '<svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none"> <path d="M47.1667 11.667C47.7855 11.667 48.379 11.9128 48.8166 12.3504C49.2542 12.788 49.5 13.3815 49.5 14.0003C49.5 14.6192 49.2542 15.2127 48.8166 15.6502C48.379 16.0878 47.7855 16.3337 47.1667 16.3337H44.8333L44.8263 16.4993L42.6493 46.9983C42.5655 48.1757 42.0387 49.2776 41.1749 50.082C40.3112 50.8864 39.1747 51.3337 37.9943 51.3337H19.0033C17.823 51.3337 16.6865 50.8864 15.8227 50.082C14.959 49.2776 14.4321 48.1757 14.3483 46.9983L12.1713 16.5017C12.1678 16.4457 12.1662 16.3897 12.1667 16.3337H9.83333C9.21449 16.3337 8.621 16.0878 8.18342 15.6502C7.74583 15.2127 7.5 14.6192 7.5 14.0003C7.5 13.3815 7.74583 12.788 8.18342 12.3504C8.621 11.9128 9.21449 11.667 9.83333 11.667H47.1667ZM33.1667 4.66699C33.7855 4.66699 34.379 4.91282 34.8166 5.35041C35.2542 5.78799 35.5 6.38149 35.5 7.00033C35.5 7.61916 35.2542 8.21266 34.8166 8.65024C34.379 9.08783 33.7855 9.33366 33.1667 9.33366H23.8333C23.2145 9.33366 22.621 9.08783 22.1834 8.65024C21.7458 8.21266 21.5 7.61916 21.5 7.00033C21.5 6.38149 21.7458 5.78799 22.1834 5.35041C22.621 4.91282 23.2145 4.66699 23.8333 4.66699H33.1667Z" fill="#ED4040"/> </svg>',
            title: "ยืนยันการลบ",
            text: "ต้องการลบหรือไม่",
            showCancelButton: true,
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "ยืนยัน",
            confirmButtonColor: "#ED4040",
            cancelButtonColor: "#FDECEC",
            customClass: {
              cancelButton: "cancelButtonOnReject",
              popup: "borderReject",
              icon: "iconBorder-0",
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.deleteData(record.id);
              this.fetchReportAuthorizesList();
            }
          });
      },
      async deleteData(id) {
        const { data } = await HTTP.post("/DeleteReportAuthorizes", {
          id: id,
          id: id.toString(),
        });
        if (data.success) {
          this.fetchReportAuthorizesList();
          //this.$router.push(`/setting-sign`);
        }
      },
      showApprovePopup(record) {
        this.$swal
          .fire({
            iconHtml:
              '<svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none"> <path d="M8.416,3.943l1.12-1.12v9.031c0,0.257,0.208,0.464,0.464,0.464c0.256,0,0.464-0.207,0.464-0.464V2.823l1.12,1.12c0.182,0.182,0.476,0.182,0.656,0c0.182-0.181,0.182-0.475,0-0.656l-1.744-1.745c-0.018-0.081-0.048-0.16-0.112-0.224C10.279,1.214,10.137,1.177,10,1.194c-0.137-0.017-0.279,0.02-0.384,0.125C9.551,1.384,9.518,1.465,9.499,1.548L7.76,3.288c-0.182,0.181-0.182,0.475,0,0.656C7.941,4.125,8.234,4.125,8.416,3.943z M15.569,6.286h-2.32v0.928h2.32c0.512,0,0.928,0.416,0.928,0.928v8.817c0,0.513-0.416,0.929-0.928,0.929H4.432c-0.513,0-0.928-0.416-0.928-0.929V8.142c0-0.513,0.416-0.928,0.928-0.928h2.32V6.286h-2.32c-1.025,0-1.856,0.831-1.856,1.856v8.817c0,1.025,0.832,1.856,1.856,1.856h11.138c1.024,0,1.855-0.831,1.855-1.856V8.142C17.425,7.117,16.594,6.286,15.569,6.286z" fill="#5f94df"  transform="scale(2.5, 3)" stroke-width="2"/> </svg>',
            title: "ยื่นขออนุมัติ",
            text: "ยืนยันการยื่นข้ออนุมัติรายการนี้",
            showCancelButton: true,
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "ยืนยัน",
            confirmButtonColor: "#5f94df",
            cancelButtonColor: "#FDECEC",
            customClass: {
              cancelButton: "cancelButtonOnReject",
              popup: "borderSubmit",
              icon: "iconBorder-0",
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.approveData(record.id);
              this.fetchReportAuthorizesList();
            }
          });
      },
      async approveData(id) { 
        const { data } = await HTTP.post("/ApproveReportAuthorizes", {
          id: id,
          id: id.toString(),
        });
        if (data.success) {
          this.fetchReportAuthorizesList();
          //this.$router.push(`/setting-sign`);
        }
      },
      ReportPDF(data) {
        // alert(data.id);
        // this.$router.push(`../app-services/print.php?report=econ_report_1&id=${data.id}`);
        
      },
      ReportWord(data) {
        // this.$router.push(`/http://178.128.220.83/app-services/export.php?report=econ_report_1&id=${data.id}`);
      },


      handlePageChange(page) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: page,
        };
        this.fetchReportAuthorizesList();
      },

      handlePageSizeChange(current, pageSize) {
        this.paginationConfig = {
          ...this.paginationConfig,
          current: current,
          pageSize: pageSize,
        };
      },
    },

    data() {
      return {
        loader: "",
        search_value: null,
        sortOptions: 0,

        columns: [
          {
            title: "ชื่องานวิจัย",
            dataIndex: "letter_project_topic",
            width: 250,
          },
          {
            title: "ชื่อหัวหน้าโครงการ",
            dataIndex: "letter_project_head",
            width: 150,
          },
          {
            title: "ชื่อผู้ถูกเรียนเชิญ",
            dataIndex: "letter_invited",
            width: 150,
          },
          {
            title: "สังกัดผู้ถูกเรียนเชิญ",
            dataIndex: "letter_invited_organization",
            width: 150,
          },
          {
            title: "เลขที่หนังสือ/ลงวันที่",
            dataIndex: "letter_code",
            width: 150,
          },
          {
            title: "จัดการข้อมูล",
            dataIndex: "manage_data",
            align: "center",
            width: 80,
          },
          {
            title: "ยื่นขออนุมัติ",
            dataIndex: "approve_data",
            align: "center",
            width: 80,
          },
          {
            title: "รายงาน",
            dataIndex: "report_data",
            align: "center",
            width: 80,
          },
        ],
     
        paginationConfig: {
          total: 0,
          pageSize: 10,
          current: 1,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 50, 100],
          onChange: this.handlePageChange,
          onShowSizeChange: this.handlePageSizeChange,
        },

        dataValues: [],
        letter_project_topic: [],
        letter_project_begin: [],
        letter_project_end: [],
        dataLength: 0,
        research_name: [],
      };
    },
    

    
  };
  </script>
  
  <style scoped></style>